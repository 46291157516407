import React from "react";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { Chart } from "primereact/chart";
import { isEmpty } from "lodash";

// import ReactApexChart from "react-apexcharts";

export const splitMyString = (str, splitLength) => {
  let a = str.split(' '), b = [];
  while(a.length) {
    if(b.length < 1 ){
      b.push(a.splice(0,splitLength).join(' ').concat('...'));
    } 
    else {
      break;
    }
  }
  return b;
}

const BarChartHorizontal = (props) => {

  const options = {
    legend: {
      position: 'bottom',
      labels: {
        fontColor: "#495057",

      },

    },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
          label: function(tooltipItem,data) {
              var value = props.data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if(value!==undefined)
              {
              value = value.toFixed(0)    
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value;
              }
              
          }
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: "#495057",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: "#495057",
            // fontSize: 9,
            callback: function(label) {
              if (/\s/.test(label)) {
                return splitMyString(label,3)
              }else{
                return label;
              } 
        
          }
           }
        },
      ],
    },
  };

  return  (!isEmpty(props?.data?.datasets)) ? (<><Chart type={props.chartType} data={props.data} options={options} height={props?.height} width={props?.width}/> <div className="charts__axes"> <span><b>{props.text}</b></span> </div> </>) : <div class="overlay"><div>No data available</div></div>;
};

export default BarChartHorizontal;

// class ApexChart extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       legend: {
//         position: 'top',
//         horizontalAlign: 'left',
//         offsetX: 40
//       },
//       series: [{
//         data: [44, 55, 41, 64, 22, 43, 21,44, 55, 41, 64, 22, 43, 21,44, 55, 41, 64, 22, 43, 21,44, 55, 41, 64, 22, 43, 21]
//       }, {
//         data: [53, 32, 33, 52, 13, 44, 32,44, 55, 41, 64, 22, 43, 21,44, 55, 41, 64, 22, 43, 21,44, 55, 41, 64, 22, 43, 21]
//       }],
//       options: {
//         chart: {
//           type: 'bar',
//           height: 430
//         },
//         plotOptions: {
//           bar: {
//             horizontal: true,
//             dataLabels: {
//               position: 'top',
//             },
//           }
//         },
//         dataLabels: {
//           enabled: true,
//           offsetX: -6,
//           style: {
//             fontSize: '12px',
//             colors: ['#fff']
//           }
//         },
//         stroke: {
//           show: true,
//           width: 1,
//           colors: ['#fff']
//         },
//         tooltip: {
//           shared: true,
//           intersect: false
//         },
//         xaxis: {
//           categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
//           'United States', 'China', 'Germany','South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
//           'United States', 'China', 'Germany','South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
//           'United States', 'China', 'Germany'],
//         },
//       },
//     };
//   }



//   render() {
//     return (
      

// <div id="chart">
// <ReactApexChart options={this.state.options} series={this.props.data} type="bar" height={350} />
// </div>
//     )
// }
// }

// export default ApexChart;