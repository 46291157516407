export const lostSalesInitialState = {
    isLostSalesAllDataLoading: false,
    isLostSalesAllDataFailed: false,
    isLostSalesAllDataSuccess: false,
    lostSalesDetailsTableData: [],
    lostSalesGraphData: [],
    fiscalWeekOptions: [],
    lostSalesUnitsValue: null,
    lostSalesRevenueValue: null,
    isLostSalesDetailsDataLoading: false,
    isLostSalesDetailsDataFailed: false,
    isLostSalesDetailsDataSuccess: false,
    departmentOptions: [],
    genderOptions: [],
    subCatOptions: [],
    dcsOptions: [],
    level5Options: [],
    level6Options: [],
    level7Options: [],
    level8Options: [],
    styleOptions: [],
    colorOptions: [],
    articleIdOptions: [],
    storeIdOptions: [],
    filterlabels: {},
    isLostSalesFilterLoading: false,
    isLostSalesFilterFailed: false,
    lostSalesRequestBody: {},
    latestWeek: null,
    lostSalesExcelData: [],
    nextIndex: 0,
    totalCount: 0,
    downloadNextIndex: 0,
    downloadTotalCount: 0,
    downloadExcelError: null,
    downloadLoading: false,
};