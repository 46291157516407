import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { intersectionWith, isEqual } from 'lodash';
import { handleSelectAll } from "../../../utils/SelectAllHandler/SelectAllHander";
import MultiSelect from "../../../components/Filters/MultiSelect";
import Select from "../../../components/Filters/SingleSelect";
import * as lostSalesActions from './actions/LostSalesReportActions';
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import {
    LOST_SALES_FILTER_DEPARTMENT, LOST_SALES_FILTER_GENDER, LOST_SALES_FILTER_SUBCATEGORY, LOST_SALES_FILTER_DCS,
    LOST_SALES_FILTER_STYLE, LOST_SALES_FILTER_COLOR, LOST_SALES_FILTER_ARTICLE_ID, LOST_SALES_FILTER_STORE_ID
} from './LostSalesConstants';
import { levelFiltersLabelFormatter } from "../../../utils/filterLevelMapping";
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, getFilterForClients, isMandatory, getDefaultFiltersCondition, getLevelFiltersForUserPreference } from "../../../utils/commonUtilities";

/**
 * View Component with Ada Stand Alone Filters
 */
const LostSalesFilters = React.memo((props) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [subCat, setSubCat] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [level6, setLevel6] = useState(null);
    const [level7, setLevel7] = useState(null);
    const [level8, setLevel8] = useState(null);
    const [style, setStyle] = useState(null);
    const [color, setColor] = useState(null);
    const [articleId, setArticleId] = useState(null);
    const [storeId, setStoreId] = useState(null);
    const [request, setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [subCatOptions, setSubCatOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);
    const [level6Options, setLevel6Options] = useState(null);
    const [level7Options, setLevel7Options] = useState(null);
    const [level8Options, setLevel8Options] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colorOptions, setColorOptions] = useState(null);
    const [articleIdOptions, setArticleIdOptions] = useState(null);
    const [storeIdOptions, setStoreIdOptions] = useState(null);

    const { lostSalesActionsProp, handleFilterRequest, activeTab, handleResetLostSales } = props;

    // useEffect(() => {
    //     if (activeTab !== "lost") {
    //         resetFilters();
    //         getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange,onl3Change:onSubCatChange})
    //         // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    //     }
    // }, [activeTab]);

    // useEffect(() => {
    //     if (activeTab === "lost") {
    //         // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    //         getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange,onl3Change:onSubCatChange})
    //         lostSalesActionsProp.getLostSalesFiltersData({}, "department");
    //     }
    // }, [activeTab]);

    useEffect(() => {
        lostSalesActionsProp.getLostSalesFiltersData({}, "department");
    }, []);

    useEffect(() => {
        setDepartmentOptions(() => props.departmentOptions);
        setGenderOptions(() => props.genderOptions);
        setSubCatOptions(() => props.subCatOptions);
        setDCSOptions(() => props.dcsOptions);
        setLevel5Options(() => props.level5Options);
        setLevel6Options(() => props.level6Options);
        setLevel7Options(() => props.level7Options);
        setLevel8Options(() => props.level8Options);
        setStyleOptions(() => props.styleOptions);
        setColorOptions(() => props.colorOptions);
        setArticleIdOptions(() => props.articleIdOptions);
        setStoreIdOptions(() => props.storeIdOptions);
    }, [props.departmentOptions, props.genderOptions, props.subCatOptions, props.dcsOptions, props.level5Options,props.level6Options,props.level7Options,props.level8Options,
    props.styleOptions, props.colorOptions, props.articleIdOptions, props.storeIdOptions]);

    const resetRestFilters = (p_index) => {
        if (p_index === 0) {
            level5 && setLevel5(null);
            level5Options && setLevel5Options(null);
            level6 && setLevel6(null)
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null)
            level7Options && setLevel7Options(null);
            level8 && setLevel8(null)
            level8Options && setLevel8Options(null);
            gender && setGender(null);
            genderOptions && setGenderOptions(null);
        }
        if (p_index === 0 || p_index === 1) {
            level5 && setLevel5(null);
            level5Options && setLevel5Options(null);
            level6 && setLevel6(null)
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null)
            level7Options && setLevel7Options(null);
            level8 && setLevel8(null)
            level8Options && setLevel8Options(null);
            subCat && setSubCat(null);
            subCatOptions && setSubCatOptions(null);
        }
        if (p_index === 0 || p_index === 1 || p_index === 2) {
            level5 && setLevel5(null);
            level5Options && setLevel5Options(null);
            level6 && setLevel6(null)
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null)
            level7Options && setLevel7Options(null);
            level8 && setLevel8(null)
            level8Options && setLevel8Options(null);
            dcs && setDCS(null);
            dcsOptions && setDCSOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3) {
            level5 && setLevel5(null);
            level5Options && setLevel5Options(null);
            level6 && setLevel6(null)
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null)
            level7Options && setLevel7Options(null);
            level8 && setLevel8(null)
            level8Options && setLevel8Options(null);
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }

        if (p_index === 7) {
            level6 && setLevel6(null)
            level6Options && setLevel6Options(null);
            level7 && setLevel7(null)
            level7Options && setLevel7Options(null);
            level8 && setLevel8(null)
            level8Options && setLevel8Options(null);
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }
        if(p_index === 8){
            level7 && setLevel7(null)
            level7Options && setLevel7Options(null);
            level8 && setLevel8(null)
            level8Options && setLevel8Options(null);
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }
        if(p_index === 9){
            level8 && setLevel8(null)
            level8Options && setLevel8Options(null);
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }
        if(p_index === 10){
            style && setStyle(null);
            styleOptions && setStyleOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3 || p_index === 4 || p_index === 7) {
            color && setColor(null);
            colorOptions && setColorOptions(null);
        }

        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3 || p_index === 4 || p_index === 5 || p_index === 7) {
            articleId && setArticleId(null);
            articleIdOptions && setArticleIdOptions(null);
        }
        if (p_index === 0 || p_index === 1 || p_index === 2 || p_index === 3 || p_index === 4 || p_index === 5 || p_index === 6 || p_index === 7) {
            storeId && setStoreId(null);
            storeIdOptions && setStoreIdOptions(null);
        }
    };

    const onDepartmentChange = (val,p_userPreference) => {
        setDepartment(val);
        if(p_userPreference?.user_preference) { 
            lostSalesActionsProp.getLostSalesFiltersData({ level1: [val] },'l10');
          }
          else{
          lostSalesActionsProp.getLostSalesFiltersData({ level1: [val] }, "gender");
          }
          setRequest(old => {
            return {
              ...old,
              'department': val,
            }
          })
        resetRestFilters(0);
    };

    const onGenderChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            setGender(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'gender': valuesArray[0]
                }
            })
            let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
            if(event?.user_preference) { 
                lostSalesActionsProp.getLostSalesFiltersData({ level1: [event?.l1_name], level2: val },'sub_category');
        }
              else{
                lostSalesActionsProp.getLostSalesFiltersData({ level1: [department], level2: req_val },  "sub_category")
              }
    }
    else{
            setGender(val);
            if (event?.user_preference) {
                lostSalesActionsProp.getLostSalesFiltersData({ level1: [event?.l1_name], level2: [val] }, "l11");
            }
            else {
                lostSalesActionsProp.getLostSalesFiltersData({ level1: [department], level2: [val] }, "sub_category");
            }
            setRequest(old => {
                return {
                    ...old,
                    'gender': val,
                }
            })
        }
        resetRestFilters(1)
        // }
    };

    // const onGenderChange = (val, event, that) => {
    //     if (Array.isArray(val)) {
    //         let valuesArray = handleSelectAll(val, event, that);
    //         let req_val = intersectionWith(valuesArray[0], genderOptions, isEqual);
    //         setGender(valuesArray[1]);
    //         setRequest(old => {
    //             return {
    //                 ...old,
    //                 'level2': valuesArray[0],
    //             }
    //         });

    //         if(event?.user_preference) { 
    //             lostSalesActionsProp.getLostSalesFiltersData({ level1: [event?.l1_name], level2: val },"sub_category");
    //           }
    //           else{
    //         lostSalesActionsProp.getLostSalesFiltersData({ level1: [department], level2: req_val }, "sub_category");
    //     }
    //         // lostSalesActionsProp.getLostSalesFiltersData({ level1: [department], level2: req_val }, "sub_category");
    //     }
    //     else {
    //         setGender(val);
    //         if(event?.user_preference) { 
    //             lostSalesActionsProp.getLostSalesFiltersData({ level1: [event?.l1_name], level2: [val] },"sub_category");
    //           }
    //           else{
    //            lostSalesActionsProp.getLostSalesFiltersData({ level1: [department], level2: [val] }, "sub_category");
    //           }
    //           setRequest(old => {
    //             return {
    //               ...old,
    //               'gender': val,
    //             }
    //           })
    //     }
    //     resetRestFilters(1);
    // };

    const onSubCatChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that)
            setSubCat(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level3': valuesArray[0],
                }
            });
                var req_val = intersectionWith(valuesArray[0], subCatOptions, isEqual);
                if (event?.user_preference) {
                    lostSalesActionsProp.getLostSalesFiltersData({ level1: [event?.l1_name], level2: [event?.l2_name], level3:val }, "dcs");
                }
                else {
                    lostSalesActionsProp.getLostSalesFiltersData(
                        { level1: [department], level2: [gender], level3:req_val }, "dcs"
                    );
                }
              
          
        }
        else {
            setSubCat(val);
            if (event?.user_preference) {
                lostSalesActionsProp.getLostSalesFiltersData({ level1: [event?.l1_name], level2: [event?.l2_name], level3: [val] }, "dcs");
            }
            else {
                lostSalesActionsProp.getLostSalesFiltersData({ level1: [department], level2: [gender], level3: [val] }, "dcs");
            }

            setRequest(old => {
                return {
                  ...old,
                  'level3': val,
                }
              })

        }
        resetRestFilters(2);
        // }
    };

    
    const onDCSChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], dcsOptions, isEqual);
            setDCS(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level4': valuesArray[0],
                }
            });
            
            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: req_val,
            }, "level5");
        }
        else {
            setDCS(val);
            lostSalesActionsProp.getLostSalesFiltersData(
                { level1: [department], level2: gender, level3: subCat, level4: [val] },
                "level5"
            );
        }
        resetRestFilters(3);
    };

    const onLevel5Change = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], level5Options, isEqual);
            setLevel5(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level5': valuesArray[0],
                }
            });
            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: dcs, level5: req_val
            }, "level6");
        }
        else {
            setLevel5(val);
            lostSalesActionsProp.getLostSalesFiltersData(
                { level1: [department], level2: gender, level3: subCat, level4: dcs, level5: [val] },
                "level6"
            );
        }
        resetRestFilters(7);
    };

    const onLevel6Change =(val,event,that)=>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], level6Options, isEqual);
            setLevel6(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level6': valuesArray[0],
                }
            });

            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: dcs, level5:level5,level6:req_val
            }, "level7");
        }
        else {
            setLevel6(val);
            lostSalesActionsProp.getLostSalesFiltersData(
                { level1: [department], level2: gender, level3: subCat, level4: dcs, level5:level5 , level6:[val]},
                "level7"
            );
        }
        resetRestFilters(8);
    }

    const onLevel7Change =(val,event,that)=>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], level7Options, isEqual);
            setLevel7(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level7': valuesArray[0],
                }
            });

            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: dcs, level5: level5, level6:level6,level7:req_val
            }, "level8");
        }
        else {
            setLevel7(val);
            lostSalesActionsProp.getLostSalesFiltersData(
                { level1: [department], level2: gender, level3: subCat, level4: dcs, level5:level5 ,level6:level6, level7:[val]},
                "level8"
            );
        }
        resetRestFilters(9);
    }

    const onLevel8Change =(val,event,that)=>{
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], level8Options, isEqual);
            setLevel8(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'level8': valuesArray[0],
                }
            });

            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: dcs, level5: level5, level6:level6, level7:level7 ,level8:req_val
            }, "style");
        }
        else {
            setLevel8(val);
            lostSalesActionsProp.getLostSalesFiltersData(
                { level1: [department], level2: gender, level3: subCat, level4: dcs, level5:level5 ,level6:level6, level7:level7, level8:[val]},
                "style"
            );
        }
        resetRestFilters(10);
    }

    const onStyleChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], styleOptions, isEqual);
            setStyle(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'style': valuesArray[0],
                }
            });
            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: dcs, level5: level5, level6:level6,level7:level7, level8:level8, style: req_val,
            }, "color");
        }
        else {
            setStyle(val);
            lostSalesActionsProp.getLostSalesFiltersData(
                { level1: [department], level2: gender, level3: subCat, level4: dcs,level5: level5, style: [val] },
                "color"
            );
        }
        resetRestFilters(4);
    };

    const onColorChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], colorOptions, isEqual);
            setColor(valuesArray[1]);
            setRequest(old => {
                return {
                    ...old,
                    'color': valuesArray[0],
                }
            });

            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: dcs, level5: level5, level6:level6,level7:level7,level8:level8, style:style, color: req_val,
            }, "article");
        }
        else {
            setColor(val);
            lostSalesActionsProp.getLostSalesFiltersData(
                {
                    level1: [department], level2: gender, level3: subCat, level4: dcs,level5: level5,
                    style: style, color: [val],
                },
                "article"
            );
        }
        resetRestFilters(5);
    };

    const onArticleIdChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], articleIdOptions, isEqual);
            setArticleId(valuesArray[1]);
            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: [request?.gender], level3: request?.level3,
                level4: dcs, level5: level5, level6:level6,level7:level7,level8:level8, style:style,color:color, article: req_val,
            }, "store");

            setRequest(old => {
                return {
                    ...old,
                    'article': valuesArray[0],
                }
            });
        }
        else {
            setArticleId(val);
            lostSalesActionsProp.getLostSalesFiltersData({
                level1: [department], level2: gender, level3: subCat, level4: dcs, level5: level5,style: style,
                color: color, article: [val],
            }, "store");
        }
        resetRestFilters(6);
    };

    const onStoreIdChange = (val, event, that) => {
        if (Array.isArray(val)) {
            let valuesArray = handleSelectAll(val, event, that);
            let req_val = intersectionWith(valuesArray[0], storeIdOptions, isEqual);
            setStoreId(valuesArray[1]);
            // lostSalesActionsProp.getLostSalesFiltersData({
            //     level1: [department], level2: [request?.gender], level3:[request?.subCat],
            //     level4: [dcs], level5: [level5], level6:[level6],level7:[level7], style:[style], color:[color], article:[articleId], store: req_val,
            // });
            setRequest(old => {
                return {
                    ...old,
                    'store': valuesArray[0],
                }
            });
        }
        else {
            setStoreId(val);
            // lostSalesActionsProp.getLostSalesFiltersData(
            //     {
            //         level1: [department], level2: [gender], level3: [subCat], level4: [dcs], level5: [level5],style: [style],
            //         color: [color], article: [articleId], store: [val],
            //     },
            // );
        }
    };

    const applyFilters = () => {
        if (getDefaultFiltersCondition({l1_name: department, l2_name: gender,l3_name: subCat})) {
            handleFilterRequest({
            //    "level1": department,
            //    "level2": gender,
            //    "level3" :subCat,
            //     "level4":dcs,
                department,
                gender,
                subCat,
                dcs,
                level5,
                level6,
                level7,
                level8,
                style,
                color,
                articleId,
                storeId,
            });
            setUserPreferenceFilter({"user_id":localStorage.getItem("user_id")},getLevelFiltersForUserPreference({l1_name:department,l2_name:gender,l3_name:subCat}))
            // setUserPreferenceFilter({ "user_id": localStorage.getItem("user_id"), "department": department });
        } else {
            Notify.error("Please Select All Mandatory Options!!");
        }
    };

    const resetFilters = () => {
        setRequest(null)
        setDepartment(null);
        setGender(null);
        setSubCat(null);
        setDCS(null);
        setLevel5(null);
        setLevel6(null);
        setLevel7(null);
        setLevel8(null);
        setStyle(null);
        setColor(null);
        setArticleId(null);
        setStoreId(null);

        handleResetLostSales();
        lostSalesActionsProp.getLostSalesFiltersData({}, "department");
    };

    useEffect(() => {
        getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange,onl3Change:onSubCatChange})
        // getUserPreferenceFilter({ "user_id": localStorage.getItem("user_id") }, onDepartmentChange);
    }, []);

    const getDDLabel = (label) => label ? label : "";

    return (
        <>
            {/* <Notification /> */}
            <section className="row section" style={{ flexDirection: "column" }}>
                <div className="row" style={{ flexDirection: "column", marginTop: "0" }}>
                    <div className="row">
                        <div className="col-md-3 col-md-3-dd required mb-3">
                            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
                            <Select
                                name="department"
                                value={department}
                                onChange={onDepartmentChange}
                                placeHolder={`Select ${props.filterLabels?.level1}`}
                                options={departmentOptions?.length ? departmentOptions : []}
                            />
                        </div>
                        <div className={`col-md-3 col-md-3-dd mb-3 ${isMandatory("l2_name")}`}>
                        {getFilterForClients({
                            label:props.filterLabels?.level2, 
                            name: "gender", 
                            dropdownValue: gender,
                            onDropDownChange: onGenderChange, 
                            placeHolder: `Select `, 
                            optionsArray:  (genderOptions?.length) ? genderOptions : [],
                            level: "l2_name",
                            isStandAlone: true
                        })}
                            {/* <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
                            <MultiSelect
                                name="gender"
                                dropdownValue={gender}
                                onDropDownChange={onGenderChange}
                                placeHolder={`Select ${getDDLabel(props.filterLabels?.level2)}`}
                                optionsArray={genderOptions?.length ? genderOptions : []}
                            /> */}
                        </div>
                        {
                            !shouldHideForCurrentClient("l3_name") &&
                            <>
                                <div className={`col-md-3 col-md-3-dd mb-3 ${isMandatory("l3_name")}`}>
                                {/* {getFilterForClients({
                                    label:props.filterLabels?.level3, 
                                    name: "subCat", 
                                    dropdownValue: subCat,
                                    onDropDownChange: onSubCatChange, 
                                    placeHolder: `Select `, 
                                    optionsArray:  (subCatOptions?.length) ? subCatOptions : [],
                                    level: "l3_name",
                                    isStandAlone: true
                                })} */}
                                   <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
                                    <MultiSelect
                                        name="subCat"
                                        dropdownValue={subCat}
                                        onDropDownChange={onSubCatChange}
                                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level3)}`}
                                        optionsArray={subCatOptions?.length ? subCatOptions : []}
                                    /> 
                                </div>
                                <div className="col-md-3 col-md-3-dd mb-3">
                                    <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
                                    <MultiSelect
                                        name="dcs"
                                        dropdownValue={dcs}
                                        onDropDownChange={onDCSChange}
                                        placeHolder={`Select ${getDDLabel(props.filterLabels?.level4)}`}
                                        optionsArray={dcsOptions?.length ? dcsOptions : []}
                                    />
                                </div>
                            </>
                        }
                        {
                            !shouldHideForCurrentClient("l5_name") &&
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                                <MultiSelect
                                    name="level5"
                                    dropdownValue={level5}
                                    onDropDownChange={onLevel5Change}
                                    placeHolder={`Select ${getDDLabel(props.filterLabels?.level5)}`}
                                    optionsArray={level5Options?.length ? level5Options : []}
                                />
                            </div>
                        }
                           {
                            !shouldHideForCurrentClient("l6_name") &&
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                                <MultiSelect
                                    name="level6"
                                    dropdownValue={level6}
                                    onDropDownChange={onLevel6Change}
                                    placeHolder={`Select ${getDDLabel(props.filterLabels?.level6)}`}
                                    optionsArray={level6Options?.length ? level6Options : []}
                                />
                            </div>
                        }
                           {
                            !shouldHideForCurrentClient("l7_name") &&
                            <div className="col-md-3 col-md-3-dd mb-3">
                                <label className="fnt-md fnt-bold">{props.filterLabels?.level7}</label>
                                <MultiSelect
                                    name="level7"
                                    dropdownValue={level7}
                                    onDropDownChange={onLevel7Change}
                                    placeHolder={`Select ${getDDLabel(props.filterLabels?.level7)}`}
                                    optionsArray={level7Options?.length ? level7Options : []}
                                />
                            </div>
                        }
                        {
                                !shouldHideForCurrentClient("l8_name") &&
                                <div className="col-md-3 col-md-3-dd mb-3">
                                    <label className="fnt-md fnt-bold">{props.filterLabels?.level8}</label>
                                    <MultiSelect
                                        name="level8"
                                        dropdownValue={level8}
                                        onDropDownChange={onLevel8Change}
                                        placeHolder={`Select ${props.filterLabels?.level8}`}
                                        optionsArray={level8Options?.length ? level8Options : []}
                                    />
                                </div>
                            }
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_STYLE}</label>
                            <MultiSelect
                                name="style"
                                dropdownValue={style}
                                onDropDownChange={onStyleChange}
                                placeHolder={'Select Style'}
                                optionsArray={styleOptions?.length ? styleOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_COLOR}</label>
                            <MultiSelect
                                name="color"
                                dropdownValue={color}
                                onDropDownChange={onColorChange}
                                placeHolder={'Select Color'}
                                optionsArray={colorOptions?.length ? colorOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_ARTICLE_ID}</label>
                            <MultiSelect
                                name="articleId"
                                dropdownValue={articleId}
                                onDropDownChange={onArticleIdChange}
                                placeHolder={"Select Article"}
                                optionsArray={articleIdOptions?.length ? articleIdOptions : []}
                            />
                        </div>
                        <div className="col-md-3 col-md-3-dd">
                            <label className="fnt-md fnt-bold">{LOST_SALES_FILTER_STORE_ID}</label>
                            <MultiSelect
                                name="storeId"
                                dropdownValue={storeId}
                                onDropDownChange={onStoreIdChange}
                                placeHolder={"Select Store"}
                                optionsArray={storeIdOptions?.length ? storeIdOptions : []}
                            />
                        </div>
                        <div>
                            <button
                                onClick={applyFilters}
                                className="btn btn-primary filter-constraints-mode"
                                title="Apply filters"
                            >
                                <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                            </button>
                            <button
                                onClick={() => resetFilters()}
                                className="btn undo-constraints-mode"
                                title="Reset filters"
                            >
                                <i className="fa fa-undo mr-2"></i>Reset
                            </button>
                        </div>
                        <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
                            <label></label>Fields are mandatory
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
);

const mapStateToProps = ({ lostSalesReport }) => ({
    departmentOptions: lostSalesReport.departmentOptions,
    genderOptions: lostSalesReport.genderOptions,
    subCatOptions: lostSalesReport.subCatOptions,
    dcsOptions: lostSalesReport.dcsOptions,
    level5Options: lostSalesReport.level5Options,
    level6Options: lostSalesReport.level6Options,
    level7Options: lostSalesReport.level7Options,
    level8Options: lostSalesReport.level8Options,
    articleIdOptions: lostSalesReport.articleIdOptions,
    filterLabels: lostSalesReport.filterlabels,
    styleOptions: lostSalesReport.styleOptions,
    colorOptions: lostSalesReport.colorOptions,
    storeIdOptions: lostSalesReport.storeIdOptions,
});

const mapDispatchToProps = (dispatch) => ({
    lostSalesActionsProp: bindActionCreators(lostSalesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostSalesFilters);