import { handleActions } from "redux-actions";
import immutable from "immutability-helper";
import { FETCH_FILTER_SUCCESS, FETCH_SKU_TABLE_DATA, FETCH_SKU_TABLE_DATA_ERROR, FETCH_SKU_TABLE_DATA_SUCCESS, FETCH_TABLE_DATA, FETCH_TABLE_DATA_ERROR, FETCH_TABLE_DATA_SUCCESS, GENERATE_EXCEL, GENERATE_EXCEL_ERROR, GENERATE_EXCEL_SUCCESS, RESET_ALL, RESET_FILTERS_DATA } from "./DailySummaryAction";

export const dailySummaryState = {
  tableData: [],
  success: false,
  error: false,
  loading: false,
  skuTableData: [],
  skuLoading: false,
  skuError: false,
  skuErrorMessage: '',
  sizeData: [],
  departmentOptions: [],
  genderOptions: [],
  subCatOptions: [],
  dcsOptions: [],
  level5Options: [],
  level6Options: [],
  level7Options: [],
  level8Options: [],
  styleOptions: [],
  colorOptions: [],
  storeIdOptions: [],
  articleIdOptions: [],
  seasonCodeOptions: [],
  seasonCodeOpenOptions: [],
  dcOptions: [],
  filterlabels: {},
  errorMessage: '',
  cardData: [],
  excelData: [],
  skuExcelData: [],
};

export default {
  dailySummary: handleActions(
    {
      [FETCH_FILTER_SUCCESS]: (state, { payload }) => {
        switch (payload.key) {
          case "l1":
            return immutable(state, {
              departmentOptions: {
                $set:
                  payload && payload.data.departmentOptions
                    ? payload.data.departmentOptions
                    : state.departmentOptions,
              },
              genderOptions: [],
              subCatOptions: [],
              dcsOptions: [],
              level5Options: [],
              level6Options: [],
              level7Options: [],
              level8Options: [],
              colorOptions: [],
              articleIdOptions: [],
              dcOptions: [],
              styleOptions: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l2":
            return immutable(state, {
              genderOptions: {
                $set:
                  payload && payload.data.genderOptions
                    ? payload.data.genderOptions
                    : state.genderOptions,
              },
              subCatOptions: {
                $set:
                  payload && payload.data.subCatOptions
                    ? payload.data.subCatOptions
                    : state.subCatOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              level8Options: {
                $set:
                  payload && payload.data.level8Options
                    ? payload.data.level8Options
                    : state.level8Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              styleOptions: [],
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l3":
            return immutable(state, {
              subCatOptions: {
                $set:
                  payload && payload.data.subCatOptions
                    ? payload.data.subCatOptions
                    : state.subCatOptions,
              },
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              level8Options: {
                $set:
                  payload && payload.data.level8Options
                    ? payload.data.level8Options
                    : state.level8Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l4":
            return immutable(state, {
              dcsOptions: {
                $set:
                  payload && payload.data.dcsOptions
                    ? payload.data.dcsOptions
                    : state.dcsOptions,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              level8Options: {
                $set:
                  payload && payload.data.level8Options
                    ? payload.data.level8Options
                    : state.level8Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l10":
            return immutable(state, {
              genderOptions: {
                $set:
                  payload && payload.data.genderOptions
                    ? payload.data.genderOptions
                    : state.genderOptions,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              level8Options: {
                $set:
                  payload && payload.data.level8Options
                    ? payload.data.level8Options
                    : state.level8Options,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l11":
              return immutable(state, {
                subCatOptions: {
                  $set:
                    payload && payload.data.subCatOptions
                      ? payload.data.subCatOptions
                      : state.subCatOptions,
                },
                dcsOptions: {
                  $set:
                    payload && payload.data.dcsOptions
                      ? payload.data.dcsOptions
                      : state.dcsOptions,
                },
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
              });
          case "l8":
            return immutable(state, {
              level5Options: {
                $set:
                  payload && payload.data.level5Options
                    ? payload.data.level5Options
                    : state.level5Options,
              },
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              level8Options: {
                $set:
                  payload && payload.data.level8Options
                    ? payload.data.level8Options
                    : state.level8Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              dcOptions: {
                $set:
                  payload && payload.data.dcOptions
                    ? payload.data.dcOptions
                    : state.dcOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l9":
            return immutable(state, {
              level6Options: {
                $set:
                  payload && payload.data.level6Options
                    ? payload.data.level6Options
                    : state.level6Options,
              },
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              level8Options: {
                $set:
                  payload && payload.data.level8Options
                    ? payload.data.level8Options
                    : state.level8Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l12":
            return immutable(state, {
              level7Options: {
                $set:
                  payload && payload.data.level7Options
                    ? payload.data.level7Options
                    : state.level7Options,
              },
              level8Options: {
                $set:
                  payload && payload.data.level8Options
                    ? payload.data.level8Options
                    : state.level8Options,
              },
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l13":
              return immutable(state, {
                level8Options: {
                  $set:
                    payload && payload.data.level8Options
                      ? payload.data.level8Options
                      : state.level8Options,
                },
                colorOptions: {
                  $set:
                    payload && payload.data.colorOptions
                      ? payload.data.colorOptions
                      : state.colorOptions,
                },
                articleIdOptions: {
                  $set:
                    payload && payload.data.articleIdOptions
                      ? payload.data.articleIdOptions
                      : state.articleIdOptions,
                },
                styleOptions: {
                  $set:
                    payload && payload.data.styleOptions
                      ? payload.data.styleOptions
                      : state.styleOptions,
                },
                storeIdOptions: {
                  $set:
                    payload && payload.data.storeIdOptions
                      ? payload.data.storeIdOptions
                      : state.storeIdOptions,
                },
                seasonCodeOptions: {
                  $set:
                    payload && payload.data.seasonCodeOptions
                      ? payload.data.seasonCodeOptions
                      : state.seasonCodeOptions,
                },
                seasonCodeOpenOptions: {
                  $set:
                    payload && payload.data.seasonCodeOpenOptions
                      ? payload.data.seasonCodeOpenOptions
                      : state.seasonCodeOpenOptions,
                },
                filterlabels: {
                  $set:
                    payload && payload.data.filterMapping
                      ? payload.data.filterMapping
                      : state.filterMapping
                }
              });
              case "l15":
                return immutable(state, {
                  colorOptions: {
                    $set:
                      payload && payload.data.colorOptions
                        ? payload.data.colorOptions
                        : state.colorOptions,
                  },
                  articleIdOptions: {
                    $set:
                      payload && payload.data.articleIdOptions
                        ? payload.data.articleIdOptions
                        : state.articleIdOptions,
                  },
                  styleOptions: {
                    $set:
                      payload && payload.data.styleOptions
                        ? payload.data.styleOptions
                        : state.styleOptions,
                  },
                  storeIdOptions: {
                    $set:
                      payload && payload.data.storeIdOptions
                        ? payload.data.storeIdOptions
                        : state.storeIdOptions,
                  },
                  seasonCodeOptions: {
                    $set:
                      payload && payload.data.seasonCodeOptions
                        ? payload.data.seasonCodeOptions
                        : state.seasonCodeOptions,
                  },
                  seasonCodeOpenOptions: {
                    $set:
                      payload && payload.data.seasonCodeOpenOptions
                        ? payload.data.seasonCodeOpenOptions
                        : state.seasonCodeOpenOptions,
                  },
                  filterlabels: {
                    $set:
                      payload && payload.data.filterMapping
                        ? payload.data.filterMapping
                        : state.filterMapping
                  }
                }); 
          case "l14":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
             
          case "l5":
            return immutable(state, {
              colorOptions: {
                $set:
                  payload && payload.data.colorOptions
                    ? payload.data.colorOptions
                    : state.colorOptions,
              },
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l6":
            return immutable(state, {
              articleIdOptions: {
                $set:
                  payload && payload.data.articleIdOptions
                    ? payload.data.articleIdOptions
                    : state.articleIdOptions,
              },
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          case "l7":
            return immutable(state, {
              styleOptions: {
                $set:
                  payload && payload.data.styleOptions
                    ? payload.data.styleOptions
                    : state.styleOptions,
              },
              storeIdOptions: {
                $set:
                  payload && payload.data.storeIdOptions
                    ? payload.data.storeIdOptions
                    : state.storeIdOptions,
              },
              seasonCodeOptions: {
                $set:
                  payload && payload.data.seasonCodeOptions
                    ? payload.data.seasonCodeOptions
                    : state.seasonCodeOptions,
              },
              seasonCodeOpenOptions: {
                $set:
                  payload && payload.data.seasonCodeOpenOptions
                    ? payload.data.seasonCodeOpenOptions
                    : state.seasonCodeOpenOptions,
              },
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
          default:
            return immutable(state, {
              filterlabels: {
                $set:
                  payload && payload.data.filterMapping
                    ? payload.data.filterMapping
                    : state.filterMapping
              }
            });
        };
      },
      [RESET_FILTERS_DATA]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: [] },
          genderOptions: { $set: [] },
          subCatOptions: { $set: [] },
          dcsOptions: { $set: [] },
          level5Options:{$set:[]},
          level6Options:{$set:[]},
          level7Options:{$set:[]},
          level8Options:{$set:[]},
          styleOptions: { $set: [] },
          colorOptions: { $set: [] },
          storeIdOptions: { $set: [] },
          articleIdOptions: { $set: [] },
          seasonCodeOptions: { $set: [] },
          seasonCodeOpenOptions: { $set: [] },
          error: { $set: false },
          success: { $set: false },
          loading: { $set: false },
          errorMessage: { $set: '' },
          cardData: { $set: [] },
          excelData: { $set: [] },
          skuExcelData: { $set: [] },
          skuTableData: { $set: [] },
          skuLoading: { $set: false },
          skuError: { $set: false },
          skuErrorMessage: { $set: ''},
          sizeData: { $set: []},
        });
      },
      [FETCH_TABLE_DATA]: (state, { payload }) => {
        return immutable(state, {
          success: { $set: false },
          error: { $set: false },
          loading: { $set: true },
          errorMessage: { $set: '' },
          excelData: { $set: [] },
        });
      },
      [FETCH_TABLE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: [] },
          loading: { $set: false },
          error: { $set: true },
          success: { $set: false },
          errorMessage: { $set: payload.error },
          cardData: { $set: [] },
          excelData: { $set: [] },
        });
      },
      [FETCH_TABLE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          tableData: { $set: (payload && payload.data) || [] },
          loading: { $set: false },
          error: { $set: false },
          success: { $set: true },
          errorMessage: { $set: '' },
          cardData: { $set: (payload && payload?.cardData) || [] },
          excelData: { $set: (payload && payload?.excelData) || [] },
        });
      },
      [FETCH_SKU_TABLE_DATA]: (state, { payload }) => {
        return immutable(state, {
          skuError: { $set: false },
          skuLoading: { $set: true },
          skuErrorMessage: { $set: '' },
          sizeData: {$set: []},
          skuExcelData: { $set: [] },
        });
      },
      [FETCH_SKU_TABLE_DATA_ERROR]: (state, { payload }) => {
        return immutable(state, {
          skuTableData: { $set: [] },
          skuLoading: { $set: false },
          skuError: { $set: true },
          skuErrorMessage: { $set: payload.error },
          sizeData: {$set: []},
          skuExcelData: { $set: [] },
        });
      },
      [FETCH_SKU_TABLE_DATA_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          skuTableData: { $set: (payload && payload.data) || [] },
          sizeData: {$set: (payload && payload.sizeData) || []},
          skuLoading: { $set: false },
          skuError: { $set: false },
          skuErrorMessage: { $set: '' },
          skuExcelData: { $set: (payload && payload?.excelData) || [] },
        });
      },
      [GENERATE_EXCEL]: (state, { payload }) => {
        return immutable(state, {
          excelSuccess: { $set: false },
          excelError: { $set: false },
          loading: { $set: true },
        });
      },
      [GENERATE_EXCEL_ERROR]: (state, { payload }) => {
        return immutable(state, {
          excelData: { $set: [] },
          loading: { $set: false },
          excelError: { $set: true },
          excelSuccess: { $set: false },
        });
      },
      [GENERATE_EXCEL_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          excelData: { $set: (payload && payload.data) || [] },
          loading: { $set: false },
          excelError: { $set: false },
          excelSuccess: { $set: true },
        });
      },
    },
    dailySummaryState
  ),
};


