import React from "react";

const Indicator = (props) => {
  return (
    <div className={"d-flex align-items-center " + props.className}>
      <div
        className="indicate-div sister-store__map"
        style={{ backgroundColor: props.color }}
      ></div>
      <span className="ml-2 font-italic mb-0">{props.text}</span>
    </div>
  );
};

export default Indicator;
