import React, { useEffect, useMemo, useRef, useState } from 'react'
import './StoreGroup.css'
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import ReactTable from "../../components/Table/ReactTable"
import { Tab, Tabs } from 'react-bootstrap';
import CreateStoreGroupModal from '../../components/Clustering/CreateStoreGroupModal';
import EditIconCell from '../../components/Table/Cellrenderer/EditIconCell';
import { connect } from 'react-redux';
import { storeGroupAction, storeDetailsAction, setstoreDataInFirstOrSecondTable, resetStoreGroup, deleteStoreGroup, resetAll, storeGroupActionForEdit, createStoreGroupFiltersAction } from './StoreGroupAction'
import PageLoader from '../../components/Loader/PageLoader';
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications"
import ColumnFilter from '../UserManagement/ColumnFilter';
import { scrollIntoView, getStoreGradeColumnNamesByClient, isAshley, isCoach ,isTapestry_Eu,getFilterForClients,shouldHideForCurrentClient} from '../../utils/commonUtilities';
import NumberRangeColumnFilter from '../../components/Filters/NumberRangeColumnFilter';
import CreateEditStoreGroup from './CreateEditStoreGroup/CreateEditStoreGroup';
import { levelFiltersLabelFormatter } from '../../utils/filterLevelMapping';
import ReactTollTip from '../../components/Table/Cellrenderer/ReactToolTip';
import Filter from "../../containers/ProductProfile/ProductProfileTable/Filter";
import { intersectionWith, isEmpty, isEqual } from "lodash";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const Store =  (props) => {


  let filterOptions = {}
for(let i in props?.storeFilters) {
  filterOptions[i] = props?.storeFilters[i]?.map(val => {
    return {
      label : val,
      value: val
    }
  })
}


const tableRef = useRef(null);

const [showStoreTable,setShowStoreTable] = useState(false)
const [storeGradeColumns, setStoreGradeColumns]=  useState()
const [request,setRequest] = useState(null)
const [brand, setBrand] = useState(null);


const storecolumns = React.useMemo(() => [
    {
        id: "store_code",
        Header: 'Store Number',
        accessor: 'store_code',
        Filter: ColumnFilter,
    },
    {
        Header: 'Store Name',
        accessor: 'store_name',
        width: 250,
        Filter: ColumnFilter,
    },
    {
      Header: 'Country',
      accessor: 'country',
      width: 250,
      Filter: ColumnFilter,
  },

    // {
    //     Header: 'Store Grade',
    //     accessor: 'store_grade',
    //     Filter: ColumnFilter,
    // },
    storeGradeColumns,
    {
        Header: 'Region Description',
        accessor: 'region',
        Filter: ColumnFilter,
    },

],[storeGradeColumns])

const storeGroupColumn = React.useMemo(
    () => 
     [
        {
            Header: levelFiltersLabelFormatter("level1"),
            id: "l1_name",
            accessor: data => data?.l1_name[0] ? data.l1_name.join(", ") : data?.description,
            // Cell: (instance) => <ReactTollTip {...instance} />,
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 <ReactTollTip {...instance} />         
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 250,
          },
          {
            Header: levelFiltersLabelFormatter("level2"),
            accessor: 'l2_name',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 <ReactTollTip {...instance} />         
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 200,
          }, 
          {
            Header: levelFiltersLabelFormatter("level3"),
            accessor: data => data?.l3_name?.join(", ") ,
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
              <ReactTollTip {...instance} />         
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 200,
          }, 
          {
            Header: levelFiltersLabelFormatter("level4"),
            accessor: 'l4_name',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            Filter: ColumnFilter,
            width: 200,
          }, 
          {
            Header: 'Store Eligibility Group Name',
            accessor: 'title',
            Filter: ColumnFilter,
            Cell: (instance) => (
                <div className="row min-100">
                  <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                    <div className="clickable" onClick = {() => {
                      // getStoreDetails(instance)
                        setStoreGroupName('')
                        let l_reqBody =   {
                            "store_group_code":[instance.row?.original?.store_group_code],
                            "Attributes":{
                                "store_group_code":[instance.row?.original?.store_group_code]
                            },
                            "Operators":{
                                "NonAttributeColumn":["store_group_code"]
                            }
                        }
                        props.storeDetailsAction(l_reqBody)
                      }
                      }>
                        {instance.row?.original?.title}
                    </div>
                  </span>
                </div>
              ),
              width: 250,
          },
          {
            Header: '# Stores',
            accessor: 'no_of_stores',
            width: 150,
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Created By',
            accessor: 'created_by',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            width: 250,
            Filter: ColumnFilter,
          },
          {
            Header: 'Modified By',
            accessor: 'updated_by',
            Cell: (instance) =>
            <div className="row min-100">
              <span className={"min-100 pt-2 "+ (instance?.row?.original?.is_default ? "sister-store__map": "")}>
                 {instance.value}        
              </span>
            </div>,
            width: 250,
            Filter: ColumnFilter,
          },
          {
            Header: "Actions",
            accessor: "actions",
            sticky: "right",
            Cell: (instance) => (
              <div>
                <EditIconCell
                  handleEdit={(instance) => {
                    let req = {
                      storeGroupCode: instance?.store_group_code,
                      storeCode: instance?.stores_codes,
                      storeGroupName: instance?.title,
                      description: instance?.description,
                    }
                      showStoreTable && setShowStoreTable(false)
                      setIsCreateStoreGroup(false);
                      setShowCreateStoreGroupModal(true);
                      setExistingStoreCode(req)
                      props.storeGroupActionForEdit({
                        store_group_code: [instance?.store_group_code],
                      });
                  }}
                  handleDelete={instance?.row?.original?.is_default ? false : (instance) => {
                    if (window.confirm("Are you sure you wish to delete this store eligibility group?")){
                    setShowStoreTable(false)
                    props.deleteStoreGroup({"store_group_code":instance?.store_group_code})
                    }}}
                  instance={instance}
                ></EditIconCell>
              </div>
            ),
            resizable: true,
            disableFilters: true,
          },
    ],
  [props,showStoreTable])

 const [existingStoreCode, setExistingStoreCode] = useState({})
 const [showCreateStoreGroupModal, setShowCreateStoreGroupModal] = useState(false)
 const [storeGroupTableData, setStoreGroupTableData] = useState([])
 const [storesStoreData, setStoresStoreData] = useState([])
 const [storeGroupName, setStoreGroupName] = useState()
 const [isCreateStoreGroup, setIsCreateStoreGroup] = useState()
 

 const getStoreGrade = (p_storeGrade) => {
    const gradeMapping = {
        Apparel : 'store_grade_ap',
        Accessories : 'store_grade_ac',
        Footwear : 'store_grade_ft'
    }
        return gradeMapping[p_storeGrade]
 }

 const getStoreDetails = (p_instance) => {
    // setStoreGroupName('')
    let l_instance = {...p_instance}
    let l_reqBody =   {
        "store_group_code":[l_instance.row?.original?.store_group_code],
        "Attributes":{
            "store_group_code":[l_instance.row?.original?.store_group_code]
        },
        "Operators":{
            "NonAttributeColumn":["store_group_code"]
        }
    }
    props.storeDetailsAction(l_reqBody)
 }

 const getStoreGroupData = React.useCallback((p_storeGroupData) => {
    let l_storeGroupData = p_storeGroupData.map(val => {
        return { 
            ...val,
            'no_of_stores' : val['stores_codes'].length,
        }
    })

    l_storeGroupData = l_storeGroupData.sort(function(a,b){return b.is_default-a.is_default});
    setStoreGroupTableData(l_storeGroupData)
 },[])

 const getStoresStoreData = React.useCallback((p_storesStoreData) => {
     let l_storesStoreData = p_storesStoreData.map(val => {
         return {
            ...val,
            'region': val.region?.[0],
            // 'store_grade': val[getStoreGrade(val.description)]?.[0],
         }
     })
     setStoresStoreData(l_storesStoreData)
 },[])

 const closeCreateStoreGroupModal = React.useCallback(() => {
    setShowCreateStoreGroupModal(false);
    props.resetStoreGroup()
 },[props])

 const createStoreGroup = React.useCallback(() => {
    showStoreTable &&  setShowStoreTable(false)
    setIsCreateStoreGroup(true);
    setShowCreateStoreGroupModal(true);
    setExistingStoreCode({})
    props.setstoreDataInFirstOrSecondTable("firstTable")
 },[props,showStoreTable])

 const editStoreGroup = React.useCallback((p_instanceData) => {
     let req = {
        storeGroupCode: p_instanceData?.store_group_code,
        storeCode: p_instanceData?.stores_codes,
        storeGroupName: p_instanceData?.title,
        description: p_instanceData?.description,
     }
    showStoreTable && setShowStoreTable(false)
    setIsCreateStoreGroup(false);
    setShowCreateStoreGroupModal(true);
    setExistingStoreCode(req)
    props.storeGroupActionForEdit({
        store_group_code: [p_instanceData?.store_group_code],
    });
 },[props,showStoreTable])

 const NotifySuccess = (createOrEdit) => {
   setTimeout(() => {
     Notify.success(`Store Eligibility Group ${createOrEdit} Successfully!!`)
   },[2000])
 }

 const deleteStoreGroup = (p_instance) => {
    if (window.confirm("Are you sure you wish to delete this store eligibility group?")){
        setShowStoreTable(false)
        props.deleteStoreGroup({"store_group_code":p_instance?.store_group_code})
    }
 }

 useEffect(() => {
    if (props.deleteSuccess) {
      Notify.success("Store Eligibility Group deleted Successfully!!");
      props.storeGroupAction({});
    }
    if (props.deleteError) {
      Notify.error("Error in deleting Store Eligibility Group!!");
    }
  }, [props.deleteError, props.deleteSuccess]);

    useEffect(() => {
        if(Object.keys(existingStoreCode).length){
            props.setstoreDataInFirstOrSecondTable("secondTable")
        }
    }, [existingStoreCode])   

    useEffect(()=>{
      props.getStoreFiltersApi({});
    },[])
    
    useEffect(() => { 
        if(props.storeGroupData.length)
          getStoreGroupData(props.storeGroupData);
    }, [props.storeGroupData])

    useEffect(() => { 
        if(props.storeGroupData.length && props.storesStoreData?.length)
        {
            getStoresStoreData(props.storesStoreData)
            let l_storeGroupName = props.storeGroupData.find(value => value.store_group_code == props.storesStoreData[0]['store_group_code'])['title']
            setShowStoreTable(true)
            setStoreGroupName(l_storeGroupName)
            scrollIntoView(tableRef)
        }
    }, [props.storesStoreData])

    

    useEffect(() => {
        props.storeGroupAction({});
        return () => {
            props.resetAll()
        }
    }, [])

    useEffect(() => {
        let storeGradeColumn = getStoreGradeColumnNamesByClient()
        storeGradeColumn && setStoreGradeColumns(storeGradeColumn)
    }, [])


    const onBrandChange = (value,event,that) => {
      if (Array.isArray(value)) {
    
        let brand = props?.storeFilters?.brand?.length ? props?.storeFilters?.brand : props?.storeFilters?.brand
    
        let valuesArray = handleSelectAll(value,event,that)
        setRequest(old => {
          return {
            ...old,
            'brand': valuesArray[0],
           
          }
        })
        setBrand(valuesArray[1])
        let req_val = intersectionWith(valuesArray[0], brand, isEqual);
      }
      else {
        setBrand([value])
        
      }
    
    };


    const prep = [
      getFilterForClients({
        id: 1,
        label: levelFiltersLabelFormatter("level1"),
        name: "l1_name",
        dropdownValue: brand,
        // onDropDownChange: onLevel1Change,
        onDropDownChange: onBrandChange,
        placeHolder:  `Select ${levelFiltersLabelFormatter("level1")}`,
        optionsArray: filterOptions.brand ? filterOptions.brand : [],
        hide: shouldHideForCurrentClient("l1_name", "storeEligibilityGroup"),
        style: { "marginBottom": "10px" },
        isStandAlone: false,
        level: "l1_name",
      },"storeEligibilityGroupScreen"),
    ]

    const applyFilters =()=>{
      if(brand.length!==0){
      props.storeGroupAction({l1_name:brand?.map((ele) => ele.value)});
      }else{
        Notify.error("Select One Brand Value!!");
      }
    }

    return (
        <>
        <div className="store__container">
        {/* {!showCreateStoreGroupModal &&  <Notification /> } */}
            <div className="container__header__with__button">
                <h1 className="fnt-lg fnt-bold">Store Eligibility Groups</h1>
                <div className="container__button">
                    <button className="btn-primary-rounded" onClick={createStoreGroup}>
                        <i className="fa fa-plus" title="Create store group" aria-hidden="true"></i>
                    </button>
                    <p className="fnt-sm">Create Store Eligibility Group</p>
                </div>
            </div>
            <div className="container__body">
                <div className="container__tabs">
                    <Tabs defaultActiveKey="ucss">
                        <Tab eventKey="ucss" title="User Created Store Eligibility Groups">
                          <div class="mx-9 p-2" style={{marginTop:"10px"}}>
                            <div style={{display:"flex"}}>
                        <Filter data={prep} />
                        <div className="col-md-3">
                          <button
                            onClick={applyFilters}
                            className="btn btn-primary filter-constraints-mode"
                            title="Apply filters"
                            // disabled={disable}
                          >
                            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
                          </button>
                          </div>
                          </div>
                          {/* {
                          
                          <button
                            // onClick={() => resetFilters("All")}
                            className="btn undo-constraints-mode"
                            title="Reset filters"
                            // disabled={disable}
                          >
                            <i className="fa fa-undo mr-2"></i>Reset
                          </button>
                          } */}
                        </div>
                            <div className="store__tables">
                                <div className="store__group__table">
                                <PageLoader loader={props.storeGroupLoading} gridLoader={true}>
                                    {(props.storeGroupError) ?  (<div className="error">{props.storeGroupError}</div>) :
                                        (storeGroupTableData.length ? (
                                        <ReactTableWithPlugins
                                        shouldPagination
                                        totalRecordsLen={storeGroupTableData.length}
                                        //embededScroll
                                        data={storeGroupTableData}
                                        columns={storeGroupColumn}
                                        renderMarkup='TableMarkup'
                                        keyRT="sortAndSearch"
                                        tableId="store_group1"
                                        tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                        headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                       />
                                    ):null)}
                                    <div className="mt-3 d-flex align-items-center">
                                        <div className = "indicate-div sister-store__map"></div>
                                        <span className = "ml-2 font-italic mb-0"> Default Store Eligibility Group</span>
                                    </div>
                                </PageLoader>
                                </div>
                                <hr/>
                                <div ref={tableRef} className="stores__table">
                                <PageLoader loader={props.storesStoreLoading} gridLoader={true}>
                                    {(props.storesStoreError) ?  (<div className="error">{props.storesStoreError}</div>) :
                                        (storesStoreData.length && showStoreTable ?  (
                                        <>
                                        <div className="storesStore__heading">
                                            <h1 class="fnt-md fnt-bold">{storeGroupName}</h1>
                                            <h1 class="fnt-md fnt-bold">Stores</h1>
                                        </div>  
                                       
                                            <ReactTableWithPlugins
                                            hideColumnsFilter
                                            // shouldPagination
                                            // tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                            // headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                            // totalRecordsLen={storesStoreData.length}
                                            embededScroll
                                            data={storesStoreData}
                                            columns={storecolumns}
                                            renderMarkup='TableMarkup'
                                            keyRT="sortAndSearch"
                                            tableId="store_group2"
                                            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                            />
                                        </>
                                    ):null)}
                                </PageLoader>
                            </div>
                            </div>
                        </Tab>
                        {
                            (!isAshley() && !isCoach() && !isTapestry_Eu()) &&
                            <Tab eventKey="ai" title="Assortment Input" disabled></Tab>
                        }
                          
                    </Tabs>
                </div>
            </div>
        </div>
         {showCreateStoreGroupModal ? (
            <CreateEditStoreGroup
              showAlert={true}
              closeAlert={closeCreateStoreGroupModal}
              existingStoreCode={existingStoreCode}
              isCreateStoreGroup = {isCreateStoreGroup}
              NotifySuccess={(createOrEdit) => NotifySuccess(createOrEdit)}
            />
          ) : null}
    </>
    )
}


    const mapStateToProps = (store) => {
        return {
           storeFilters: store.storeGroup?.storeGroupFiltersData,
        storeGroupLoading: store.storeGroup?.storeGroupLoading,
        storesStoreLoading: store.storeGroup?.storesStoreLoading,
        storeGroupData: store.storeGroup?.storeGroupData,
        storeGroupError: store.storeGroup?.storeGroupError,
        storesStoreData: store.storeGroup?.storesStoreData,
        storesStoreError: store.storeGroup?.storesStoreError,
        deleteSuccess: store.storeGroup?.deleteSuccess,
        deleteError: store.storeGroup?.deleteError,
        };
    };

    const mapDispatchToProps = (dispatch) => ({
        storeGroupAction: (payload) => dispatch(storeGroupAction(payload)),
        storeGroupActionForEdit:  (payload) => dispatch(storeGroupActionForEdit(payload)),
        storeDetailsAction: (payload) => dispatch(storeDetailsAction(payload)),
        setstoreDataInFirstOrSecondTable: (payload) => dispatch(setstoreDataInFirstOrSecondTable(payload)),
        deleteStoreGroup: (payload) => dispatch(deleteStoreGroup(payload)),
        getStoreFiltersApi: (payload) => dispatch(createStoreGroupFiltersAction(payload)),
        resetStoreGroup: () => dispatch(resetStoreGroup()),
        resetAll: () => dispatch(resetAll()),
    });

export default connect(mapStateToProps, mapDispatchToProps)(Store);