import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as lostSalesActions from './actions/LostSalesReportActions';
import LostSalesReportView from './LostSalesReportView';
import { REQ_BODY_KEY } from './LostSalesConstants';

/**
 * Lost Sales Report Container
 * with handler methods
 */
class LostSalesReportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleFilterRequest = this.handleFilterRequest.bind(this);
        this.handleResetLostSales = this.handleResetLostSales.bind(this);
    }

    handleResetLostSales() {
        const { lostSalesReportActionsProp } = this.props;
        lostSalesReportActionsProp.resetLostSalesReport();
        lostSalesReportActionsProp.resetFilterOptions();
    }

    handleFilterRequest(request) {
        const { lostSalesReportActionsProp } = this.props;
        const { getLostSalesReportAllData, setLostSalesReqBody } = lostSalesReportActionsProp;

        let reqBody = {};
        if (request) {
            console.log('ss',request)
            Object.keys(request).forEach(key => {
                if (key === "department") {
                    reqBody[REQ_BODY_KEY[key]] = [request?.department.value];
                }
                else if(key === "gender"){
                    if(Array.isArray(request[key]))
                    {
                        reqBody[REQ_BODY_KEY[key]] = request[key].map(item => item.value)
                    }
                    else{
                    reqBody[REQ_BODY_KEY[key]] = [request?.gender.value];
                    }
                }
                // else if(key === "subCat"){
                //     reqBody[REQ_BODY_KEY[key]] = [request?.subCat.value];
                // }
                 else if (request[key] && request[key].length > 0) {
                    reqBody[REQ_BODY_KEY[key]] = request[key].map(item => item.value)
                }
            });
            // reqBody['year'] = [new Date().getFullYear()];

            setLostSalesReqBody(reqBody);
            getLostSalesReportAllData({...reqBody, row_index: 0, row_count: 100});
        }
    }

    render() {
        const { activeTab } = this.props;
        return (
            <>
                {
                    activeTab === "lost" &&
                    <LostSalesReportView handleFilterRequest={this.handleFilterRequest}
                        handleResetLostSales={this.handleResetLostSales}
                        activeTab={activeTab} />

                }
            </>
        );
    }
}
const mapStateToProps = ({ lostSalesReport }) => ({

});

const mapDispatchToProps = (dispatch) => ({
    lostSalesReportActionsProp: bindActionCreators(lostSalesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LostSalesReportContainer);