import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import NumberRangeColumnFilter from "../../../components/Filters/NumberRangeColumnFilter";
import ExportFile from "../../../components/ExportFile/ExportFile";
import WeekFilter from "./WeekFilter";
import PageLoader from "../../../components/Loader/PageLoader";
import {
  addDollar,
  numberUSFormatting,
  twoDecimal,
} from "../../../utils/formatters/valueFormatters";
import {
  LOST_SALES_DETAILS_TABLE_HEADERS,
  LOST_SALES_REVENUE_LABEL,
  LOST_SALES_UNITS_LABEL,
  LOST_SALES_DETAILS_TABLE_HEADER,
  LOST_SALES_NETWORK_ERROR_MSG,
  LOST_SALES_NO_RECORDS_HELPER_MSG,
  LOST_SALES_NO_RECORDS_MSG,
} from "./LostSalesConstants";
import * as lostSalesActions from "./actions/LostSalesReportActions";
import "./LostSalesReport.css";
import { cloneDeep, isEmpty } from "lodash";
import ColumnFilterServerSide from "../../../components/Filters/ColumnFilterServerSide";
import DownloadProgressBar from "../../../components/DownloadProgressBar/DownloadProgressBar";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import NumberRangeColumnFilterServerSide from "../../../components/Filters/NumberRangeColumnFilterServerSide";
import { LOST_SALES_EXCEL_COLUMNS_FORMATTING } from "../../../components/ExportFile/ExportExcelConstants";

const pageSize = 10;

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const LostSalesReportDetailsTable = (props) => {
  const [lostSalesTableInst, setLostSalesTableInst] = useState(null);
  const [lostSalesTablesData, setTableData] = useState([]);
  const [prevIndex, setPrevIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);
  const [searchTermReq, setSearchTermReq] = useState({});
  const [sortReq, setSortReq] = useState({});
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [downloadExcelLoading, setDownloadLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [isFirstCall, setisFirstCall] = useState(true);
  const [reqBody, setRequest] = useState({});

  const handleLostSalesMount = (inst) => {
    setLostSalesTableInst(inst);
  };

  const lostSalesTableColumns = React.useMemo(
    () => [
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.FISCAL_WEEK,
        accessor: "fiscal_week",
        disableFilters: true,
        width: 100,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.FISCAL_YEAR,
        accessor: "year",
        disableFilters: true,
        width: 100,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STORE_ID,
        accessor: "store_code",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.store_code?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        filter: "bulkFilterSpaceSeperated",
        width: 140,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STORE_NAME,
        accessor: "store_name",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.store_name?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 180,
      },
      {
        sticky: "left",
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.ARTICLE_ID,
        accessor: "article",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.article?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "array", ",")
            }
            placeholder="Search in bulk by ','"
          />
        ),
        filter: 'bulkFilterCommaSeperated',
        width: 140,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_ID,
        accessor: "style_code",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.style_code?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.COLOR_ID,
        accessor: "color_code",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.color_code?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_DESC,
        accessor: "style_name",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.style_name?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 300,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.STYLE_COLOR,
        accessor: "color_desc",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.color_desc?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.COLOR,
        accessor: "color",
        Filter: (instance) => (
          <ColumnFilterServerSide
            {...instance}
            searchTermValue={searchTermReq?.color?.value}
            changeHandler={(term, id) =>
              fecthDataWithSearchTerm(term, id, "string")
            }
          />
        ),
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.UNITS_SOLD,
        accessor: "units",
        Cell: (inst) => numberUSFormatting(inst?.value),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.units?.value?.min}
            maxSearchTermValue={searchTermReq?.units?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.AVG_STORE_CLUSTER_UNITS,
        accessor: "cluster_avg_sales",
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.cluster_avg_sales?.value?.min}
            maxSearchTermValue={searchTermReq?.cluster_avg_sales?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.LOST_OPPORTUNITY_SALES,
        accessor: "lost_sales",
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.lost_sales?.value?.min}
            maxSearchTermValue={searchTermReq?.lost_sales?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        Cell: (inst) => (
          <div>
            {inst.value || inst.value === 0
              ? addDollar(numberUSFormatting(inst.value))
              : null}
          </div>
        ),
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.LOST_OPPORTUNITY_UNITS,
        accessor: "lost_units",
        Cell: (inst) => numberUSFormatting(inst?.value),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.lost_units?.value?.min}
            maxSearchTermValue={searchTermReq?.lost_units?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
      {
        Header: LOST_SALES_DETAILS_TABLE_HEADERS.OPENING_INVENTORY,
        accessor: "opening_inventory",
        Cell: (inst) => numberUSFormatting(inst?.value),
        Filter: (instance) => (
          <NumberRangeColumnFilterServerSide
            {...instance}
            minSearchTermValue={searchTermReq?.opening_inventory?.value?.min}
            maxSearchTermValue={searchTermReq?.opening_inventory?.value?.max}
            clickHandler={(min, max, id) =>
              fecthDataWithSearchTerm([min, max], id, "range")
            }
          />
        ),
        filter: "between",
        width: 150,
      },
    ],
    []
  );

  const {
    lostSalesDetailsTableData,
    lostSalesUnitsValue,
    lostSalesRevenueValue,
    fiscalWeekOptions,
    lostSalesActionsProp,
    lostSalesRequestBody,
    isLostSalesAllDataFailed,
    downloadNextIndex,
    downloadTotalCount,
    isLostSalesDetailsDataLoading,
    isLostSalesDetailsDataFailed,
    latestWeek,
    lostSalesExcelData,
    downloadExcelError,
  } = props;
  const {
    getLostSalesReportDetailsTableData,
    generateExcel,
    resetDownloadExcel,
  } = lostSalesActionsProp;

  // useEffect(() => {
  //     fiscalWeekOptions.sort(sortFiscalWeekToDesc)
  // }, [fiscalWeekOptions])

  const sortFiscalWeekToDesc = (a, b) => {
    return parseInt(b.value) - parseInt(a.value);
  };

  const handleWeekSelect = (weekValue) => {
    const yearData = props.lostSalesGraphData.find(
      (item) => item.week == weekValue
    )?.year;
    const request = {
      ...lostSalesRequestBody,
      week: [weekValue],
      year: [yearData],
      row_index: 0,
      row_count: 100,
    };
    setRequest(request);
    getLostSalesReportDetailsTableData(request);
    setPrevIndex(0);
    setInitialIndex(0)
    setTotalCount(0);
    setNextIndex(0);
    setSearchTermReq({});
    setSortReq({});
    setTableData([]);
    setLostSalesTableInst(null);
  };

  useEffect(() => {
    const yearData = props.lostSalesGraphData.find(item => item.week == latestWeek?.value)?.year;
    setRequest({ ...lostSalesRequestBody, week: [latestWeek && latestWeek?.value], year: [yearData]});
  }, [lostSalesRequestBody]);

  useEffect(() => {
    if (lostSalesDetailsTableData?.length) {
      if (lostSalesTableInst && lostSalesTableInst.data) {
        setTableData([
          ...lostSalesTableInst.data,
          ...lostSalesDetailsTableData,
        ]);
      } else {
        setTableData(lostSalesDetailsTableData);
      }
    }
  }, [lostSalesDetailsTableData]);

  const fetchData = (index) => {
    if (prevIndex >= index || nextIndex >= totalCount) return;
    setPrevIndex(index);
    setInitialIndex(Math.floor(nextIndex / pageSize - 1));
    getLostSalesReportDetailsTableData({
      ...reqBody,
      row_index: nextIndex,
      searchColumns: searchTermReq,
      sortColumn: sortReq,
    });
  };

  const getExcelData = () => {
    generateExcel({ ...reqBody, row_index: downloadNextIndex, row_count: 500, searchColumns: searchTermReq, sortColumn: sortReq?.[0] });
  };

  // useEffect(() => {
  //     if (downloadExcelLoading && downloadNextIndex < downloadTotalCount) {
  //         getExcelData()
  //     }
  //     else if (downloadNextIndex > downloadTotalCount) {
  //         generaExcelData()
  //     }
  // }, [downloadNextIndex])

  useEffect(() => {
    if (props.nextIndex == 100) {
      setPrevIndex(0);
      // setInitialIndex(0)
    }
    setTotalCount(props.totalCount);
    setNextIndex(props.nextIndex);
  }, [props.totalCount, props.nextIndex]);

  const fecthDataWithSearchTerm = (p_term, p_id, p_type, split_type) => {
    if (p_type === "array") {
      if(split_type) {
        p_term = p_term.replace(/\s*,\s*/g, ",")
      }
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        var id = p_term.split(split_type ? split_type : " ");
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            values: id,
            search: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else if (Array.isArray(p_term)) {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        let min = p_term[0] == "" ? null : p_term[0];
        let max = p_term[1] == "" ? null : p_term[1];
        if (min == null && max == null) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: {
              min: min,
              max: max,
            },
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    } else {
      setSearchTermReq((old) => {
        let l_searchTermReq = cloneDeep(old);
        if (p_term?.trim() == 0) {
          l_searchTermReq?.[p_id] && delete l_searchTermReq[p_id];
        } else {
          l_searchTermReq[p_id] = {
            type: p_type,
            value: p_term,
          };
        }
        return {
          ...l_searchTermReq,
        };
      });
    }
    isFirstCall && setisFirstCall(false);
  };

  const fecthDataWithSorting = (p_sort) => {
    if (!isEmpty(sortReq)) {
      isFirstCall && setisFirstCall(false);
    }
    setSortReq(p_sort[0]);
  };

  useEffect(() => {
    if (
      (!isEmpty(searchTermReq) || !isFirstCall || !isEmpty(sortReq)) &&
      !isEmpty(reqBody)
    ) {
      setLostSalesTableInst(null);
      setTableData([]);
      setInitialIndex(0);
      setPrevIndex(0);
      getLostSalesReportDetailsTableData({
        ...reqBody,
        row_index: 0,
        searchColumns: searchTermReq,
        sortColumn: sortReq,
      });
    }
  }, [searchTermReq, sortReq]);

  const generateExcelData = () => {
    setExcelData(lostSalesTablesData);
    setDownloadLoading(true);
    setDownloadExcel(false);
    generateExcel({ ...reqBody, row_index: nextIndex, row_count: 500, searchColumns: searchTermReq, sortColumn: sortReq?.[0] });
  };

  useEffect(() => {
    if (lostSalesExcelData?.length) {
      setExcelData(excelData.concat(lostSalesExcelData));
      // setDownloadExcel(true)
    }
  }, [lostSalesExcelData]);

  useEffect(() => {
    if (downloadExcelLoading && downloadNextIndex < downloadTotalCount) {
      getExcelData();
    } else if (downloadNextIndex > downloadTotalCount) {
      formatExcelData(excelData.concat(lostSalesExcelData));
    }
  }, [downloadNextIndex]);

  const formatExcelData = (data) => {
    let downloadData = data.map((detail) => ({
      Store: detail.store_code,
      "Store Name": detail.store_name,
      "Fiscal Week": detail.fiscal_week,
      "Fiscal Year": detail.year,
      [`${updateArticleName(false)} ID`]: detail.article,
      Color: detail.color,
      "Style Color": detail.color_desc,
      "Units Sold": detail.units,
      "Avg Store Cluster Units": detail.cluster_avg_sales,
      "Opening Inventory": detail.opening_inventory,
      "Lost Opportunity (Units)": detail.lost_units,
      "Lost Opportunity (Revenue)": detail.lost_sales,
      "Style Description": detail.style_name,
    }));
    setExcelData(downloadData);
    setTimeout(() => {
      setDownloadLoading(false);
      setDownloadExcel(true);
    }, 1000);

    resetDownloadExcel();
  };

  useEffect(() => {
    if (downloadExcelError) {
      setDownloadLoading(false);
      // Notify.error(downloadExcelError)
      resetDownloadExcel();
    }
  }, [downloadExcelError]);

  const closeDownloadModal = () => {
    setDownloadLoading(false);
    resetDownloadExcel();
  };

  return (
    <PageLoader
      loader={isLostSalesDetailsDataLoading}
      loaderMessage="Loading..."
    >
      <DownloadProgressBar
        nextIndex={excelData?.length}
        totalCount={downloadTotalCount || totalCount}
        showModal={downloadExcelLoading}
        closeModal={closeDownloadModal}
      />
      {lostSalesDetailsTableData && lostSalesDetailsTableData.length > 0 ? (
        <div
          id="LOST_SALES_REPORT_DETAILS_TABLE_CONTAINER"
          className="lost_sales_details_table_container shadow-sm"
        >
          <h4 className="line-chart__heading">
            {LOST_SALES_DETAILS_TABLE_HEADER}
          </h4>
          <span className="total_lost_opportunity">
            <h5 className="line-chart__heading">
              {LOST_SALES_UNITS_LABEL}:{" "}
              {numberUSFormatting(lostSalesUnitsValue)}
            </h5>
            <h5 className="line-chart__heading">
              {LOST_SALES_REVENUE_LABEL}:{" "}
              {`$${numberUSFormatting(lostSalesRevenueValue.toFixed(0))}`}
            </h5>
          </span>
          <div className="d-flex justify-content-between mb-2">
            <div className="mr-4" style={{ display: "flex" }}>
              <WeekFilter
                fiscalWeekOptions={fiscalWeekOptions}
                handleWeekSelect={handleWeekSelect}
                latestWeek={latestWeek}
                lostSalesGraphData={props.lostSalesGraphData}
              />
            </div>
            <ExportFile
              downloadExcelData={downloadExcel}
              callFunc={generateExcelData}
              text={"Excel"}
              data={excelData}
              fileName={`Lost_Sales_Report_${new Date().getTime()}`}
              type={1}
              formateXLSX={true}
              excelColumnsToBeFormatted={LOST_SALES_EXCEL_COLUMNS_FORMATTING}
            />
          </div>
          <ReactTableWithPlugins
            getInstanceOnMount={(inst) => handleLostSalesMount(inst)}
            shouldPagination
            hideDropdown
            pageSize={pageSize}
            totalRecordsLen={lostSalesDetailsTableData.length}
            data={lostSalesTablesData}
            columns={lostSalesTableColumns}
            renderMarkup="TableMarkup"
            keyRT="sortAndSearch"
            tableId="lost_sales"
            fetchData={(data) => fetchData(data)}
            initialPageIndex={initialIndex}
            totalCount={totalCount}
            getManualSortByOptions={(instance) => {
              fecthDataWithSorting(instance);
            }}
            manualSortBy={true}
            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
          />
        </div>
      ) : isLostSalesAllDataFailed || isLostSalesDetailsDataFailed ? (
        <div className="error">{LOST_SALES_NETWORK_ERROR_MSG}</div>
      ) : (
        <div className="empty">
          {LOST_SALES_NO_RECORDS_MSG}
          <p>{LOST_SALES_NO_RECORDS_HELPER_MSG}</p>
        </div>
      )}
    </PageLoader>
  );
};

const mapStateToProps = ({ lostSalesReport }) => ({
  lostSalesDetailsTableData: lostSalesReport.lostSalesDetailsTableData,
  lostSalesUnitsValue: lostSalesReport.lostSalesUnitsValue,
  lostSalesRevenueValue: lostSalesReport.lostSalesRevenueValue,
  fiscalWeekOptions: lostSalesReport.fiscalWeekOptions,
  lostSalesRequestBody: lostSalesReport.lostSalesRequestBody,
  isLostSalesAllDataFailed: lostSalesReport.isLostSalesAllDataFailed,
  isLostSalesDetailsDataLoading: lostSalesReport.isLostSalesDetailsDataLoading,
  isLostSalesDetailsDataFailed: lostSalesReport.isLostSalesDetailsDataFailed,
  latestWeek: lostSalesReport.latestWeek,
  lostSalesExcelData: lostSalesReport.lostSalesExcelData,
  nextIndex: lostSalesReport.nextIndex,
  totalCount: lostSalesReport.totalCount,
  downloadNextIndex: lostSalesReport.downloadNextIndex,
  downloadTotalCount: lostSalesReport.downloadTotalCount,
  downloadExcelError: lostSalesReport.downloadExcelError,
  downloadExcelData: lostSalesReport.excelData,
  lostSalesGraphData: lostSalesReport.lostSalesGraphData,
});

const mapDispatchToProps = (dispatch) => ({
  lostSalesActionsProp: bindActionCreators(lostSalesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LostSalesReportDetailsTable);
