import React from "react";
import BarChartHorizontal from "../../components/BarchartHorizontal/BarchartHorizontal";
import StackedBarChartHorizontal from "../../components/StackedBarcharHorizontal/StackedBarchartHorizontal";
import { connect } from "react-redux";
import PieChart from "../../components/PieChart/PieChart";
import "./Dashboard.css";
import DashboardFilters from "./DashboardFilters";
import {
  getChartData,
  clearChartData,
  getTransactionDetails,
  getRecommendedActionstData,
  getStyleInventoryData,
} from "./DashboardActions";
import { cloneDeep, isEmpty, isEqual } from "lodash";

import StyleInventory from "./StyleInventory/StyleInventory";
import RecommendedAction from "./RecommendedAction/RecommendedAction";
import PageLoader from "../../components/Loader/PageLoader";
import { STRATEGY_PAGE } from "../../constants/routeConstants";
import { updateArticleName } from "../../utils/filterLevelMapping"
import Marquee from "../../components/Marquee/Marquee"
import POAllocation from "./POAllocation/POAllocation";
import { getRequestForChartAPI, hideFeatureForCurrentClient, noOfArtlcesInDashboard } from "../../utils/commonUtilities";

class Dashboard extends React.Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef();
  }

  state = {
    weekSupply: null,
    storeStock: null,
    overallStock: null,
    styleInvData: null,
    recommendedData: null,
    showPOAllocation: false,
    status: null,
    initialCall: true,
    storeFilters:null,
    // filters: { level1: [{ label: 'Accessories', value: 'Accessories' }] }
    // filters: {level1:[this.props.level1Options[0]]}
    // filters: { level1: [{ label: 'Accessories', value: 'Accessories' }] }
    filters: {}
  };

  componentDidMount() {
    this.props.getTransactionDetails({});
  }

  componentWillUnmount() {
    this.props.clearChartData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      this.setState({ showPOAllocation: false, status: null })
      this.setState({
        weekSupply: cloneDeep(this.props.weekSupply),
        storeStock: cloneDeep(this.props.storeStock),
        overallStock: cloneDeep(this.props.overallStock),
        styleInvData: this.props.styleInvData,
        recommendedData: this.props.recommendedData,
        // filters: {level1:[this.props?.level1Options ? this.props?.level1Options[0] : '']}
      });
    }
    if ((!isEqual(this.props.level1Options, prevProps.level1Options) || !isEqual(this.props.dcOptions, prevProps.dcOptions)) && !isEmpty(this.props?.level1Options) && !isEmpty(this.props.dcOptions) && this.state.initialCall) {
      let getFilter = JSON.parse(localStorage.getItem("user_preference"))
      let userId = localStorage.getItem("user_id")
      let req
      if (getFilter && !isEmpty(getFilter.filters) && getFilter.user_id == userId) {
        req = getRequestForChartAPI(getFilter.filters)
        // { label: getFilter.filter.value, value: getFilter.filter.value }
        this.props.getChartData({ ...req, dc: this.props.dcOptions });
        this.props.getStyleInventoryData({...req, dc: this.props.dcOptions,row_index: 0,"sortColumn":{"id":"lw_qty","desc":true}});
        this.props.getRecommendedActionstData({ ...req, dc: this.props.dcOptions });
        this.setState({
          filters: { ...req, dc: this.props.dcOptions, },
          initialCall: false
        });
      }
      else {
        // commenting this code to avoid selecting first l1_name as default if user_prefreence is not set

        // this.props.getChartData({ l1_name: [this.props?.level1Options[0]], dc: this.props.dcOptions });
        // this.props.getRecommendedActionstData({ l1_name: [this.props?.level1Options[0]], dc: this.props.dcOptions });
        // this.setState({
        //   filters: {
        //     l1_name: [this.props?.level1Options ? this.props?.level1Options[0] : ''],
        //     dc: this.props.dcOptions,
        //   }
        // });
      }
    }
  }


  getFilters = (p_filters) => {
    this.setState({
      filters: p_filters
    })
  }

  resetFilters = (p_filters) => {
    this.setState({
      // filters: { level1: [this.props.level1Options[0].value] }
      // filters: { l1_name: [this.props.level1Options[0]], dc: this.props.dcOptions }
      filters: p_filters
    })
  }

  setShowPOAllocation = (type, status,storeFilters) => {
    this.setState({ showPOAllocation: false })
    setTimeout(() => {
      this.setState({ showPOAllocation: type, status: status ,storeFilters: storeFilters })
    }, 200)
  }

  render() {
    const {
      styleInvData,
      weekSupply,
      storeStock,
      overallStock,
      recommendedData,
      reviewRecommendation,
      reviewRecommendedData,
      showPOAllocation,
      storeFilters,
      status,
    } = this.state;
    return (
      <div className="dashboard__container">
        <div className="container__header__with__button">
          <h1 className="fnt-lg fnt-bold">Dashboard</h1>
          <div className="container__button">
            <button className="btn-primary-rounded" onClick={() => this.props.history.push(`${STRATEGY_PAGE}`)}>
              <i
                className="fa fa-plus"
                title="Create Allocation"
                aria-hidden="true"
              ></i>
            </button>
            <p className="fnt-sm">Create Allocation</p>
          </div>
        </div>
        {this.props?.refreshDetails &&
          <Marquee text1={`Inventory. Refresh Date: ${this.props.refreshDetails.inventory_date}`} text2={`Transaction. Refresh Date: ${this.props.refreshDetails.transaction_date}`} />
        }
        <div className="filter row">
          <DashboardFilters getFilters={this.getFilters} resetFilters={this.resetFilters} />
        </div>
        <div className="row pl-4">
          <div className="required fnt-md pl-4" style={{ color: "red" }}>
            <label></label>Fields are mandatory
          </div>
        </div>
        <PageLoader top="65%" loader={this.props.loading}>
          {this.props.error ? (
            <div className="error">Something Went Wrong!!</div>
          ) : (
            <>
              {(weekSupply || storeStock) && (
                <>
                  <center>
                    <h2 className="charts__heading">Overall Inventory</h2>
                  </center>
                  <div className="dashboard__barChart__container">
                    <div className="card chart__item">
                      <h3 className="charts__heading">Weeks of Supply (Top {noOfArtlcesInDashboard()} {updateArticleName(true)})</h3>
                      {/* <div className="charts__axes">
                        <span>x-axis: <b>WOS in numbers</b></span>
                        <span>y-axis: <b>Articles</b></span>
                      </div> */}
                      {weekSupply && <BarChartHorizontal chartType="horizontalBar" data={weekSupply} text="WOS in numbers" />}
                    </div>
                    <div className="card chart__item">
                      <h3 className="charts__heading">Stock Status Distribution</h3>
                      {/* <div className="charts__axes">
                          <span>x-axis: <b>Distribution %</b></span>
                          <span>y-axis: <b>Store Grade</b></span>
                        </div> */}
                      {storeStock && (
                        <StackedBarChartHorizontal data={storeStock} />
                      )}
                    </div>
                  </div>
                </>
              )}
              {overallStock && (
                <div className="card chart__item">
                  <h3 className="pieChart__heading">Overall Stock (Top {noOfArtlcesInDashboard()}  {updateArticleName(true)})</h3>
                  <PieChart data={overallStock} legends={this.props.pieLegends} />
                </div>
              )}
            </>
          )}
          {/* Style Inventory Table Below */}
          <>
            {!hideFeatureForCurrentClient("style_inventory") && (
              <div className="style_inventory_container shadow-sm">
                {/* <h2 className="font-weight-bold mx-auto p-3 text-center">{updateArticleName(true)} Inventory</h2> */}
                <StyleInventory
                  data={styleInvData}
                  filters={this.state.filters}
                  heading={`${updateArticleName(false)} Inventory`}
                />
              </div>

            )}
          </>
          </PageLoader>

          {/* Recommended Action Items Table Below */}
          <>
            <PageLoader loader={this.props.recommendedActionsloading && !this.props.loading} gridLoader={true}>
              {this.props.recommendedActionserror ? (
                <div className="error">Something Went Wrong!!</div>
                ) : (
                recommendedData && (
                  <div className="recommended_action_container shadow-sm">
                    <h2 className="font-weight-bold mx-auto p-3 text-center">Recommended Action Items</h2>
                    <RecommendedAction
                      data={recommendedData}
                      filters={this.state.filters}
                      setShowPOAllocation={this.setShowPOAllocation}
                    />
                  </div>
              ))}
              {
                showPOAllocation &&
                <POAllocation filters={this.state.filters} type = {showPOAllocation} status={status} storeFilters={storeFilters} />
              }
            </PageLoader>
          </>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard }) => ({
  level1Options: dashboard.level1Options,
  dcOptions: dashboard.dcOptions,
  loading: dashboard.loading,
  recommendedActionsloading: dashboard.recommendedActionsloading,
  recommendedActionserror: dashboard.recommendedActionserror,
  weekSupply: dashboard.weekSupply,
  storeStock: dashboard.storeStock,
  overallStock: dashboard.overallStock,
  pieLegends: dashboard.pieLegends,
  styleInvData: dashboard.styleInvData,
  recommendedData: dashboard.recommendedData,
  error: dashboard.error,
  refreshDetails: dashboard.refreshDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getChartData: (payload) => dispatch(getChartData(payload)),
  getStyleInventoryData:(payload)=>dispatch(getStyleInventoryData(payload)),
  getRecommendedActionstData: (payload) => dispatch(getRecommendedActionstData(payload)),
  clearChartData: (payload) => dispatch(clearChartData(payload)),
  getTransactionDetails: (payload) => dispatch(getTransactionDetails(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
