import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping";

export const TABLE_COLUMNS = {
    "Article_ID" : `${updateArticleName(false)} ID`,
    "Style_ID": "Style ID",
    "Color_ID": "Color ID",
    "Style": "Style Description",
    "Style_Color": "Style Color",
    "Global_Color": "Global Color",
    "Level1": levelFiltersLabelFormatter("level1"),
    "Level2": levelFiltersLabelFormatter("level2"),
    "Level3": levelFiltersLabelFormatter("level3"),
    "Level4": levelFiltersLabelFormatter("level4"),
    "Level5": levelFiltersLabelFormatter("level5"),
    "Level6": levelFiltersLabelFormatter("level6"),
    "Level7": levelFiltersLabelFormatter("level7"),
    "Level8": levelFiltersLabelFormatter("level8"),
    "Product_Type": "Product Type"
}

export const ALLOCATION_REQUIRED_OPTIONS = [{
    label: "Active",
    value: "active"
  },
  {
    label: "Deactivated",
    value: "deactivated"
  },
  {
    label: "Paused",
    value: "paused"
  }]