import React, { useEffect, useState } from "react";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import EditIconCell from "../../../components/Table/Cellrenderer/EditIconCell";
import { connect } from "react-redux";
import {
  deleteProductProfile,
  fetchFilterData,
  fetchSavedData,
  fetchUserCreatedData,
  resetFiltersOptions,
} from "../ProductProfileAction";
import ProfileTable from "./ProfileTable";
import Filter from "./Filter";
import { fetchStoresData } from "../../ProductProfile/ProductProfileAction";
import { useHistory } from "react-router-dom";
import { PROFILE_PAGE } from "../../../constants/routeConstants";

import ReactTable from "../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../components/Table/WithPlugins";
import useSelectFilters from "../../../customHooks/useSelectFilters";
import ColumnFilter from "../../UserManagement/ColumnFilter";
import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping"
import { getLevelFiltersForUserPreference, getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, getDefaultFiltersCondition, isMandatory, getFilterForClients } from "../../../utils/commonUtilities";
import ReactTollTip from "../../../components/Table/Cellrenderer/ReactToolTip";


const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);
function UserCreatedProfile({
  // level1Options,
  // level2Options,
  // level3Options,
  // level4Options,
  ...props
}) {
  let history = useHistory();

  const [isRowClicked, setIsRowClicked] = useState(false);
  const maxWidth = "100%";
  const [column, setColumn] = useState([]);
  const [level1Options, setlevel1Options] = useState(null);
  const [level2Options, setlevel2Options] = useState(null);
  const [level3Options, setlevel3Options] = useState(null);
  const [level4Options, setlevel4Options] = useState(null);
  const [level5Options, setlevel5Options] = useState(null);
  const [level6Options, setlevel6Options] = useState(null);
  const [level7Options, setlevel7Options] = useState(null);
  const [level8Options, setlevel8Options] = useState(null);
  const [productProfileName, setProductProfileName] = useState('')

  const [
    level1,
    level2,
    level3,
    level4,
    setLevel1,
    setLevel2,
    setLevel3,
    setLevel4,
    onLevel1Change,
    onLevel2Change,
    onLevel3Change,
    onLevel4Change,
    dynamicHeader,
    request,
    resestRequest,
    dc,
    setDc,
    onDcChange,
    level5,
    setLevel5,
    onLevel5Change,
    level6,
    setLevel6,
    onLevel6Change,
    level7,
    setLevel7,
    onLevel7Change,
    level8,
    setLevel8,
    onLevel8Change
    
  ] = useSelectFilters(
    props.fetchFilterData,
    true,
    level2Options,
    level3Options,
    level4Options,
    setlevel2Options,
    setlevel3Options,
    setlevel4Options,
    null,
    null,
    level5Options,
    setlevel5Options,
    level6Options,
    setlevel6Options,
    level7Options,
    setlevel7Options,
    level8Options,
    setlevel8Options
  );

  useEffect(() => {
    setlevel1Options(() => props.level1Options);
  }, [props.level1Options]);
  

  useEffect(() => {
    setlevel2Options(() => props.level2Options);
  }, [props.level2Options]);
  
  useEffect(() => {
    setlevel3Options(() => props.level3Options);
  }, [props.level3Options]);
  
  useEffect(() => {
    setlevel4Options(() => props.level4Options);
  }, [props.level4Options]);

  useEffect(() => {
    setlevel5Options(() => props.level5Options);
  }, [props.level5Options]);

  useEffect(() => {
    setlevel6Options(() => props.level6Options);
  }, [props.level6Options]);

  useEffect(() => {
    setlevel7Options(() => props.level7Options);
  }, [props.level7Options]);

  useEffect(() => {
    setlevel8Options(() => props.level8Options);
  }, [props.level8Options]);


  useEffect(() => {
    setColumn(props.userCreatedProfileTabsColumns);
  }, [props.userCreatedProfileTabsColumns]);

  useEffect(() => {
      getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onLevel1Change,onl2Change:onLevel2Change,onl3Change:onLevel3Change})
      props.fetchFilterData({},{key:'l1'});
    // resetFilters();
  }, []);

  useEffect(() => {
    if(props.activeTab == "ppu") {
      // getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onLevel1Change,onl2Change:onLevel2Change,onl3Change:onLevel3Change})
    }
    props.resetFiltersOptions([]);
    setIsRowClicked(false);
  // resetFilters();
  }, [props.activeTab]);

  useEffect(() => {
    if (props.deleteSuccess && (props.activeTab == null || props.activeTab === "ppu")) {
      Notify.success("Product profile deleted Successfully!!");
      applyFilters();
    }
    if (props.deleteError && props.activeTab != "ppi") {
      Notify.error("Error in deleting product profile!!");
    }
  }, [props.deleteError, props.deleteSuccess]);

  const getStoresData = (p_instance) => {
    setIsRowClicked(true);
    setProductProfileName(p_instance.row?.original?.title)
    props.fetchStoresData({
      product_profile_code: p_instance.row?.original?.product_profile_code,
      "l1_name":[level1.value],"l2_name":[level2.value]
    });
  };

  const userCreatedProfileColumns = React.useMemo(() => [
    {
      Header: "Product Profile",
      accessor: "product_profile",
      Cell: (instance) => (
        <div>
          <div
            className="clickable"
            onClick={() => getStoresData(instance)}
          >
            {instance.row?.original?.product_profile}
          </div>
        </div>
      ),
      width: 250,
      Filter: ColumnFilter
      
    },
    {
      Header: levelFiltersLabelFormatter("level1"),
      accessor: "l1_name",
      disableFilters: true
    },
    {
      Header: levelFiltersLabelFormatter("level2"),
      accessor: "l2_name",
      disableFilters: true
    },
    {
      Header: levelFiltersLabelFormatter("level3"),
      accessor: "l3_name",
      Cell: (instance) => <ReactTollTip {...instance}/>,
      // accessor:(data) =>  [...new Set(data["l3_name"])].join(', '),
      Filter: ColumnFilter,
      width: 200,
    },
    {
      Header: levelFiltersLabelFormatter("level4"),
      accessor: "l4_name",
      Cell: (instance) => <ReactTollTip {...instance}/>,
      // accessor:(data) =>  [...new Set(data["l4_name"])].join(', '),
      Filter: ColumnFilter,
      width: 200,
    },
    {
      Header: levelFiltersLabelFormatter("level5"),
      accessor: "l5_name",
      Cell: (instance) => <ReactTollTip {...instance}/>,
      Filter: ColumnFilter,
      width: 200,
    },
    {
      Header: levelFiltersLabelFormatter("level6"),
      accessor: "l6_name",
      Cell: (instance) => <ReactTollTip {...instance}/>,
      Filter: ColumnFilter,
      width: 200,
    },
    {
      Header: levelFiltersLabelFormatter("level7"),
      accessor: "l7_name",
      Cell: (instance) => <ReactTollTip {...instance}/>,
      Filter: ColumnFilter,
      width: 200,
    },
    {
      Header: levelFiltersLabelFormatter("level8"),
      accessor: "l8_name",
      Cell: (instance) => <ReactTollTip {...instance}/>,
      Filter: ColumnFilter,
      width: 200,
    },
    {
      Header: "Time Period",
      accessor: "period",
      Filter: ColumnFilter
    },
    {
      Header: "Sales Attribute",
      accessor: "sales_attribute",
      width: 250,
      Filter: ColumnFilter
    },
    {
      Header: "Product Attribute",
      accessor: "color",
      width: 250,
      Filter: ColumnFilter
    },
    {
      Header: "Created By",
      accessor: "created_by",
      width: 260,
      Filter: ColumnFilter
    },
    {
      Header: "Creation Date",
      accessor: "creation_date",
      Filter: ColumnFilter
    },
    {
      Header: "Creation Time",
      accessor: "creation_time",
      Filter: ColumnFilter
    },
    {
      Header: "Actions",
      accessor: "actions",
      sticky: "right",
      Cell: (instance) => (
        <div>
          <EditIconCell
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            instance={instance}
          ></EditIconCell>
        </div>
      ),
      disableFilters: true,
      resizable: true,
      width: 100,
    },
  ]);
  
  const userCreatedProfileStyleColorColumns = React.useMemo(() => [
    {
      Header: `${updateArticleName(false)} ID`,
      accessor: "article",
      Filter: (instance) => <ColumnFilter {...instance} placeholder="Search in bulk by ','" />,
      filter: 'bulkFilterCommaSeperated',
    },
    // {
    //   Header: 'Style ID',
    //   accessor: 'style_code',
    //   Filter: ColumnFilter
    // },
    // {
    //   Header: 'Color ID',
    //   accessor: 'color_code',
    //   Filter: ColumnFilter
    // },
    {
      Header: "Style Description",
      accessor: "style_name",
      width: 250,
      Filter: ColumnFilter
    },
    {
      Header: "Global Color",
      accessor: "color",
      width: 250,
      Filter: ColumnFilter
    },
    {
      Header: "Style Color",
      accessor: "color_desc",
      width: 250,
      Filter: ColumnFilter
    },
    {
      Header: "Contribution",
      accessor: (row) =>
        Number(row.contribution)
          ? (Number(row.contribution) * 100).toFixed(1) + " %"
          : "-",
      Filter: ColumnFilter
    },
  ]);

  const handleEdit = (p_instance) => {
    history.push(`${PROFILE_PAGE}/edit/${p_instance?.product_profile_code}`);
  };

  const handleDelete = (p_instance) => {
    if (window.confirm("Are you sure you wish to delete this product profile?"))
      props.deleteProductProfile(p_instance?.product_profile_code);
  };

  // const resetRestFilters = (p_index) => {
  //     if(p_index == 0){
  //         level2 && setLevel2(null);
  //     }
  //     if(p_index == 1 || p_index == 0){
  //         level3 && setLevel3(null);
  //     }
  //     level4 && setLevel4(null)
  // }

  // const onLevel1Change = (val) => {
  //     if(val.value === "Apparel"){
  //         setDynamicHeader('DCS')
  //     }
  //     else{
  //         setDynamicHeader('Sub Category')
  //     }
  //     setLevel1(val)
  //     resetRestFilters(0)
  //     props.fetchFilterData({level1:[val]})
  //  }
  //  const onLevel2Change = (val) => {
  //      setLevel2(val)
  //      resetRestFilters(1)
  //      //API call to get next filter
  //      props.fetchFilterData({level1:[level1],level2:[val]})

  //  }
  //  const onLevel3Change = (val) => {
  //      setLevel3(val)
  //      resetRestFilters(2)
  //      props.fetchFilterData({level1:[level1],level2:[level2],level3:[val]})
  //  }

  //  const onLevel4Change = (val) => {
  //     setLevel4(val)
  // }

  const resetFilters = () => {
    setLevel1(null);
    setLevel2(null);
    setLevel3(null);
    setLevel4(null);
    setLevel5(null);
    setLevel6(null);
    setLevel7(null);
    setLevel8(null);
    setIsRowClicked(false);
    props.resetFiltersOptions([]);
    resestRequest();
  };

  const applyFilters = () => {
    setIsRowClicked(false);
    if (getDefaultFiltersCondition({l1_name: level1, l2_name: level2, l3_name: level3})) {
      setUserPreferenceFilter({"user_id":localStorage.getItem("user_id")},getLevelFiltersForUserPreference({l1_name:level1,l2_name:level2,l3_name:level3}))
      props.fetchUserCreatedData({
        level1,
        level2,
        level3: request?.level3 || level3,
        level4: request?.level4 || level4,
        level5: request?.level5 || level5,
        level6: request?.level6 || level6,
        level7: request?.level7 || level7,
        level8: request?.level8 || level8,
      });
    } else {
      Notify.error("Please Select Mandatory Option!!");
    }
  };

  const prep = [
    {
      id: 1,
      isRequired: true,
      label: props.filterLabels?.level1,
      name: "level1",
      value: level1,
      onChange: onLevel1Change,
      placeHolder: `Select ${props.filterLabels?.level1}`,
      options: level1Options?.length ? level1Options : [],
    },
    {
      id: 2,
      isRequired: isMandatory("l2_name"),
      label: props.filterLabels?.level2,
      name: "level2",
      value: level2,
      onChange: onLevel2Change,
      placeHolder: `Select ${props.filterLabels?.level2}`,
      options: level2Options?.length ? level2Options : [],
    },
    {
        id: 3,
        isRequired: isMandatory("l3_name"),
        label: props.filterLabels?.level3,
        name: "level3",
        dropdownValue: level3,
        onDropDownChange: onLevel3Change,
        placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`,
        optionsArray: level3Options?.length ? level3Options : [],
        isMultiSelect: true,
    },
    // getFilterForClients({
    //   id: 3,
    //   label: props.filterLabels?.level3,
    //   name: "level3",
    //   dropdownValue: level3,
    //   onDropDownChange: onLevel3Change,
    //   placeHolder: `Select ${props.filterLabels?.level3 ? props.filterLabels?.level3 : ""}`,
    //   optionsArray: level3Options?.length ? level3Options : [],
    //   hide: shouldHideForCurrentClient("l3_name"),
    //   style: { "marginBottom": "10px" },
    //   isStandAlone: false,
    //   level: "l3_name"
    // }),
    {
      id: 4,
      isRequired: false,
      label: props.filterLabels?.level4,
      name: "level4",
      dropdownValue: level4,
      onDropDownChange: onLevel4Change,
      placeHolder: `Select ${props.filterLabels?.level4}`,
      optionsArray: level4Options?.length ? level4Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l4_name")
    },
    {
      id: 5,
      isRequired: false,
      label: props.filterLabels?.level5,
      name: "level5",
      dropdownValue: level5,
      onDropDownChange: onLevel5Change,
      placeHolder: `Select ${props.filterLabels?.level5}`,
      optionsArray: level5Options?.length ? level5Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l5_name")
    },
    {
      id: 6,
      isRequired: false,
      label: props.filterLabels?.level6,
      name: "level6",
      dropdownValue: level6,
      onDropDownChange: onLevel6Change,
      placeHolder: `Select ${props.filterLabels?.level6}`,
      optionsArray: level6Options?.length ? level6Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l6_name")
    },
    {
      id: 7,
      isRequired: false,
      label: props.filterLabels?.level7,
      name: "level7",
      dropdownValue: level7,
      onDropDownChange: onLevel7Change,
      placeHolder: `Select ${props.filterLabels?.level7}`,
      optionsArray: level7Options?.length ? level7Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l7_name")
    },
    {
      id: 8,
      isRequired: false,
      label: props.filterLabels?.level8,
      name: "level8",
      dropdownValue: level8,
      onDropDownChange: onLevel8Change,
      placeHolder: `Select ${props.filterLabels?.level8}`,
      optionsArray: level8Options?.length ? level8Options : [],
      isMultiSelect: true,
      hide: shouldHideForCurrentClient("l8_name")
    }
  ];

  console.log('sss',props.filterLabels)

  return (
    <div className="userCreatedProfile__container">
      <section className="row section">
        {/* <Notification /> */}
        <Filter data={prep} />
        <div className="col-md-3" style={{margin:0, marginTop: "1rem"}}>
          <button
            onClick={applyFilters}
            className="btn btn-primary filter-constraints-mode"
            title="Apply filters"
          >
            <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
          </button>
          <button
            onClick={() => resetFilters("All")}
            className="btn undo-constraints-mode"
            title="Reset filters"
          >
            <i className="fa fa-undo mr-2"></i>Reset
          </button>
        </div>
        <div style={{ marginTop: "3rem" }} className="col-md-3">
          <div className="required fnt-md pt-2" style={{ color: "red" }}>
            <label></label>Fields are mandatory
          </div>
        </div>
      </section>
      {/* <div className="row section">
        <div className="required fnt-md pl-4" style={{ color: "red" }}>
          <label></label>Fields are mandatory
        </div>
      </div> */}
      <section className="row section">
        <ProfileTable
          // style={{ margin: "5rem 2rem 2rem" }}
          header = {productProfileName}
          baseTableColumns={userCreatedProfileColumns}
          baseTableData={props.userCreatedData}
          baseTableError={props.userCreatedError}
          baseTableloading={props.userCreatedLoading}
          tabColumns={column}
          tabData={props.storeData}
          tabDataError={props.storeDataError}
          tabDataLoading={props.storeLoading}
          isShowTabs={isRowClicked}
          tabColorSizeColumns={userCreatedProfileStyleColorColumns}
          tabColorSizeData={props.tabColorSizeData}
          maxWidth={maxWidth}
        />
      </section>
    </div>
  );
}

const mapStateToProps = ({ productProfile }) => ({
  userCreatedProfileTabsColumns: productProfile.storeColumn,
  level1Options: productProfile.level1Options,
  level2Options: productProfile.level2Options,
  level3Options: productProfile.level3Options,
  level4Options: productProfile.level4Options,
  level5Options: productProfile.level5Options,
  level6Options: productProfile.level6Options,
  level7Options: productProfile.level7Options,
  level8Options: productProfile.level8Options,
  filterLabels: productProfile.filterlabels,
  userCreatedData: productProfile.userCreatedData,
  userCreatedError: productProfile.userCreatedError,
  storeData: productProfile.storeData,
  storeDataError: productProfile.storeError,
  tabColorSizeData: productProfile.colourStyleData,
  userCreatedLoading: productProfile.userCreatedloading,
  storeLoading: productProfile.storeloading,
  deleteSuccess: productProfile.deleteSuccess,
  deleteError: productProfile.deleteError,
});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload, filterType) =>
    dispatch(fetchFilterData(payload, filterType)),
  fetchUserCreatedData: (payload) => dispatch(fetchUserCreatedData(payload)),
  fetchStoresData: (payload) => dispatch(fetchStoresData(payload)),
  fetchSavedData: (payload) => dispatch(fetchSavedData(payload)),
  deleteProductProfile: (payload) => dispatch(deleteProductProfile(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCreatedProfile);
