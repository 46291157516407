import { isEmpty } from "lodash";
import { takeLatest, put, all, call, delay } from "redux-saga/effects";
import {
    GET_ALLOCATION_PLANS,

    getAllocationPlansSuccess,
    getAllocationPlansError
} from './ViewAllocationActions'
import { getAllocationPlans } from "../../routes/api"
import moment from "moment";
import { cloneDeep } from "lodash";
import { dateFormat } from "../../utils/commonUtilities";

function* getAllocationPlansWorker(action) {
    try {
        const { payload } = action;
        const level_ln_map = {
          "level1": "l1_name",
          "level2": "l2_name",
          "level3": "l3_name",
          "level4": "l4_name",
          "level5": "l5_name",
          "level6": "l6_name",
          "level7": "l7_name",
          "level8": "l8_name",
          "start_date": "start_date",
          "end_date": "end_date",
        }
        let req = {};
        if (!isEmpty(payload)) {
            for (const key in payload) {
                req[level_ln_map[key]] = key == "start_date" || key == "end_date" ? payload[key][0] : (payload[key].map(function mapper(ele) {
                    if (Array.isArray(ele)) {
                        return ele.map(mapper);
                    } else {
                        return ele.value;
                    }
                }));
            }
            for (let i in req) {
                if (Array.isArray(req[i])) {
                    req[i] = req[i].flat(Infinity);
                }
            }
        }
        else {
            req = { l1_name: ["Accessories"] }
        }

        const response = yield call(getAllocationPlans, req)
        if (response.data.status) {
            let allocationPlans = cloneDeep(response.data.allocationPlanData)
            allocationPlans.forEach(plan => {
                let timeStamp = moment(plan.creation_date)
                plan.creation_date = dateFormat(plan.creation_date)
                plan.creation_time = timeStamp.format('HH:mm:ss')
                if(plan?.released_date) {
                    let releasedTimeStamp = moment(plan.released_date)
                    plan.released_date_picked = dateFormat(plan.released_date)
                    plan.released_time = releasedTimeStamp.format('HH:mm:ss')
                }
            })
            yield put(getAllocationPlansSuccess({ 'data': allocationPlans }))
        }
        else {
            yield put(getAllocationPlansError({ 'error': response.message }));
        }

    } catch (error) {
        yield put(getAllocationPlansError(error));
    }
}


function* getAllocationPlansWatcher() {
    yield takeLatest(GET_ALLOCATION_PLANS, getAllocationPlansWorker);
}

export function* viewAllocationSaga() {
    yield all([
        getAllocationPlansWatcher()
    ])
}