export const initState = {
    departmentOptions: [],
    genderOptions: [],
    rbuOptions: [],
    dcsOptions: [],
    level5Options: [],
    level6Options: [],
    level7Options: [],
    level8Options: [],
    styleOptions: [],
    colorOptions: [],
    articleOptions: [],
    storeOptions: [],
    filterlabels: {},
    excessTableData: [],
    excessGraphData: {},
    excelData: [],
    tableDataSuccess: false,
    tableDataLoading: false,
    tableDataError: null,
    graphDataSuccess: false,
    graphDataLoading: false,
    graphDataError: null,
    nextIndex: 0,
    totalCount: 0,
    weeks: [],
    yearData: [],
    sum: {},
    excelLoading: false,
    totalCountExcel: 0,
    nextIndexExcel: 0,
    excelError: false,
    excelSuccess: true,
}