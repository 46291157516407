import React from 'react'
import ReactTable from '../../../components/Table/ReactTable';
import WithDynamicPlugins from '../../../components/Table/WithPlugins';
import { Tab, Tabs } from 'react-bootstrap';
import PageLoader from '../../../components/Loader/PageLoader';
import ErrorModal from '../../../components/ErrorModal/ErrorModal';
import classnames from "classnames";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

function ProfileTable(props) {
    const { fetchData, fecthDataWithSorting, totalCount, initialPageIndex, setRTInstance } = props
    const getPenetrationComponent =  () => {
    
    return (<PageLoader loader={props.tabDataLoading} gridLoader={true}>
    <div className="productprofile_tables">
           <div className="productprofile__table" style={{maxWidth:props?.maxWidth}}>
                    {(props.tabDataError) ? (<div className="error">Something Went Wrong!!</div>) :
                    <>
                <div className="">
                    <h1 class="fnt-md fnt-bold">{props?.header}</h1>
                </div>  
                <ReactTableWithPlugins
                    hideColumnsFilter
                    // shouldPagination
                    embededScroll
                    totalRecordsLen={props?.tabData?.length}
                    data={props?.tabData}
                    columns={props?.tabColumns}
                    sortBy = {[{id:'overall', desc:true}]}
                    renderMarkup='TableMarkup'
                    keyRT="sortAndSearch"
                    tableId="profile_table1"
                    tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                    headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                />
                </>}
            </div>   
    </div>
    </PageLoader>) 
}
console.log('ss12',props.baseTableError)
    return (<>
        <div className={classnames("table","profiletable__container")} style={{...props?.style}}>
        {props.hasOwnProperty('baseTableData')?<PageLoader loader={props.baseTableloading} gridLoader={true}>
        {(props.baseTableError) ? (<> <ErrorModal message={props.baseTableError}/> <div className="error">{props.baseTableError}</div> </>) :                             
            <ReactTableWithPlugins
                shouldPagination
                totalRecordsLen={props?.baseTableData?.length}
                data={props?.baseTableData}
                sortBy = {!fetchData && [{ id: 'creation_date', desc: true },{ id: 'creation_time', desc: true }]}
                columns={props?.baseTableColumns}
                renderMarkup='TableMarkup'
                keyRT="sortAndSearch"
                tableId="profile_table2"
                {...props}                   
                fetchData={(data) => fetchData && fetchData(data)}
                getInstanceOnMount={(instance) => {
                    setRTInstance ? setRTInstance(instance) : props.getInstanceOnMount && props.getInstanceOnMount(instance)                
                }}
                getManualSortByOptions={(instance) => {
                    fecthDataWithSorting && fecthDataWithSorting(instance)
                }}
                manualSortBy={fecthDataWithSorting && true}
                initialPageIndex={initialPageIndex}
                totalCount={totalCount}       
                tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}            
            />}
        </PageLoader>:null}
        </div>
        <div className="profiletable__tabs">
                   {(props?.tabData?.length || props?.tabColorSizeData?.length || props?.isShowTabs) ? <Tabs defaultActiveKey="pts">
                        {/* <Tab eventKey="ptr" title="Region" disabled>
                            <div className="productprofile__table">
                            
                            </div>
                        </Tab>
                        <Tab eventKey="ptc" title="Cluster" disabled>
                            <div className="productprofile__table">

                            </div>
                        </Tab> */}
                        <Tab eventKey="pts" title="Store and Size Contributions">
                            <div className="profiletable__tabs">
                            {props?.tabColorSizeColumns && props?.tabColumns ? <Tabs defaultActiveKey="ptp">
                                <Tab eventKey="ptp" title="Penetration">
                                {getPenetrationComponent()}
                                </Tab>
                                {props?.tabColorSizeColumns && <Tab eventKey="ptscd" title="Color Style Description">
                        <PageLoader loader={props.tabDataLoading} gridLoader={true}>
                            <div className="productprofile__table">
                            {props?.tabColorSizeData?.length && 
                                              <div className="productprofile__stylecolor_description_table">
                                        {(props.tabDataError) ? (<div className="error">Something Went Wrong!!</div>) :
                                        <>
                                        <div className="">
                                            <h1 class="fnt-md fnt-bold">{props?.header}</h1>
                                        </div>  
                                        <ReactTableWithPlugins
                                            // shouldPagination
                                            embededScroll
                                            totalRecordsLen={props?.tabColorSizeData.length}
                                            data={props?.tabColorSizeData}
                                            columns={props?.tabColorSizeColumns}
                                            sortBy = {[{id:'overall', desc:true}]}
                                            renderMarkup='TableMarkup'
                                            keyRT="sortAndSearch"
                                            tableId="profile_table3"
                                            tableWrapperStyle={{ height: 'fit-content', maxHeight: '50rem' }}
                                            headerWrapperStyle={{ position: 'sticky', top: 0, zIndex: '4' }}
                                        /></>}
                                    </div>}
                            </div>
                        </PageLoader>
                        </Tab>}
                            </Tabs> : getPenetrationComponent() }
                            </div>
                        </Tab>
                    </Tabs>: null}
        </div>
        </>)
}

export default ProfileTable
