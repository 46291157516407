import React, { useEffect, useState } from "react";
import "./Filters.css";
import MultiSelect from "./MultiSelect";
import Select from "./SingleSelect";
import { intersectionWith, isEmpty, isEqual } from "lodash";
import { connect } from "react-redux";
import {
  resetFiltersOptions,
  fetchFilterData,
} from "../../containers/Constraints/ConstraintsAction";
import * as Notify from "../Notification/Notifications";
import Notification from "../Notification/Notifications";
import { handleSelectAll } from "../../utils/SelectAllHandler/SelectAllHander";
import { levelFiltersLabelFormatter } from  "../../utils/filterLevelMapping"
import { getUserPreferenceFilter, shouldHideForCurrentClient, setUserPreferenceFilter, getLevelFiltersForUserPreference, getDefaultFiltersCondition, isMandatory } from "../../utils/commonUtilities";
import AsyncMulti from "./AsyncMulti";
import { GET_CLASS_LIST } from "../../constants/apiConstants";

const ConstraintsFilter = React.memo(
  ({
    // departmentOptions,
    // genderOptions,
    // rbuOptions,
    // dcsOptions,
    // styleOptions,
    // colourOptions,
    // sizeOptions,
    ...props
  }) => {
    const [department, setDepartment] = useState(null);
    const [gender, setGender] = useState(null);
    const [rbu, setRbu] = useState(null);
    const [dcs, setDCS] = useState(null);
    const [level5, setLevel5] = useState(null);
    const [level6, setLevel6] = useState(null);
    const [level7, setLevel7] = useState(null);
    const [level8, setLevel8] = useState(null);
    const [style_name, setStyle] = useState(null);
    const [colour, setColour] = useState(null);
    const [size, setSize] = useState(null);
    const [storeGrade, setStoreGrade] = useState(null)
    const [request,setRequest] = useState(null)
    const [departmentOptions, setDepartmentOptions] = useState(null);
    const [genderOptions, setGenderOptions] = useState(null);
    const [rbuOptions, setRbuOptions] = useState(null);
    const [dcsOptions, setDCSOptions] = useState(null);
    const [styleOptions, setStyleOptions] = useState(null);
    const [colourOptions, setColourOptions] = useState(null);
    const [sizeOptions, setSizeOptions] = useState(null);
    const [storeGradeOptions, setStoreGradeOptions] = useState(null);
    const [level5Options, setLevel5Options] = useState(null);
    const [level6Options, setLevel6Options] = useState(null);
    const [level7Options, setLevel7Options] = useState(null);
    const [level8Options, setLevel8Options] = useState(null);
    const [countryOptions, setCountryOptions] = useState(null);
    const [country, setCountry] = useState(null);
    const [assortIndOptions, setAssortIndOptions] = useState(null);
    const [assortInd, setAssortInd] = useState(null);

    useEffect(() => {
      setDepartmentOptions(() => props.departmentOptions);
    }, [props.departmentOptions]);
    

    useEffect(() => {
      setGenderOptions(() => props.genderOptions);
    }, [props.genderOptions]);
    
    useEffect(() => {
     setRbuOptions(() => props.rbuOptions);
    }, [props.rbuOptions]);
    
    useEffect(() => {
      setDCSOptions(() => props.dcsOptions);
    }, [props.dcsOptions]);

    useEffect(() => {
      setLevel5Options(() => props.level5Options);
    }, [props.level5Options]);

    useEffect(() => {
      setLevel6Options(() => props.level6Options);
    }, [props.level6Options]);

    useEffect(() => {
      setLevel7Options(() => props.level7Options);
    }, [props.level7Options]);

    useEffect(() => {
      setLevel8Options(() => props.level8Options);
    }, [props.level8Options]);
    
    useEffect(() => {
      setStyleOptions(() => props.styleOptions);
    }, [props.styleOptions]);
    
    useEffect(() => {   
      setColourOptions(() => props.colourOptions);
    }, [ props.colourOptions]);
    
    useEffect(() => {
      setSizeOptions(() => props.sizeOptions);
    }, [props.sizeOptions]);

    useEffect(() => {
      setStoreGradeOptions(() => props.storeGradeOptions);
    }, [props.storeGradeOptions]);

    useEffect(() => {
      setCountryOptions(() => props.countryOptions);
    }, [props.countryOptions]);

    useEffect(() => {
      setAssortIndOptions(() => props.assortIndOptions);
    }, [props.assortIndOptions]);
    
    
    useEffect(() => {
      getUserPreferenceFilter({"user_id":localStorage.getItem("user_id")}, {onl1Change:onDepartmentChange,onl2Change:onGenderChange,onl3Change:onRbuChange})
      props.fetchFilterData({},{key:'l1'});
    }, []);

    const resetRestFilters = (p_index) => {
      colour && setColour(null);
      style_name && setStyle(null);
      size && setSize(null);
      storeGrade && setStoreGrade(null);
      colourOptions && setColourOptions(null)
      styleOptions && setStyleOptions(null)
      sizeOptions && setSizeOptions(null)
      storeGradeOptions && setStoreGradeOptions(null)
      assortInd && setAssortInd(null)
      assortIndOptions && setAssortIndOptions(null)
      country && setCountry(null)
      countryOptions && setCountryOptions(null)
      if (p_index == 0) {
        gender && setGender(null);
        genderOptions && setGenderOptions(null)
      }
      if (p_index == 0 || p_index == 1) {
        rbu && setRbu(null);
        rbuOptions && setRbuOptions(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2) {
        dcs && setDCS(null);
        dcsOptions && setDCSOptions(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3) {
        level5 && setLevel5(null);
        level5Options && setLevel5Options(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index == 4) {
        level6 && setLevel6(null);
        level6Options && setLevel6Options(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index ==  4 || p_index == 5) {
        level7 && setLevel7(null);
        level7Options && setLevel7Options(null)
      }
      if (p_index == 0 || p_index == 1 || p_index == 2 || p_index == 3 || p_index ==  4 || p_index == 5 || p_index===6) {
        level8 && setLevel8(null);
        level8Options && setLevel8Options(null)
      }
      
    };

    const onDepartmentChange = (val,p_userPreference) => {
      setRequest(old => {
      return {
        ...old,
        'level2': null,
        'level3': null,
        'level4': null,
        'level5': null,
        'level6': null,
        'level7': null,
        'style_name': null,
        'color': null,
        'size': null,
        'storeGrade': null,
      }
      })
      setDepartment(val);
      resetRestFilters(0);
      if(p_userPreference?.user_preference) { 
        props.fetchFilterData({ level1: [val] },{key:'l10'});
      }
      else{
        props.fetchFilterData({ level1: [val] },{key:'l2'});
      }
    };
    const onGenderChange = (val,p_userPreference) => {
      setGender(val);
      setRequest(old => {
        return {
          ...old,
          'level2': val,
          'level3': null,
          'level4': null,
          'level5': null,
          'level6': null,
          'level7': null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(1);
      //API call to get next filter
      if(p_userPreference?.user_preference) {
        props.fetchFilterData({ level1: [p_userPreference?.l1_name], level2:[val] },{key:'l3'});
      }
      else {
        props.fetchFilterData({ level1: [department], level2: [val] },{key:'l3'});
      }
    };
    const onRbuChange = (val, event, that) => {
      let valuesArray = handleSelectAll(val, event, that)
      let req_val = intersectionWith(valuesArray[0],rbuOptions, isEqual);
      setRbu(valuesArray[1]);
      setRequest(old => {
        return {
          ...old,
          'level3': valuesArray[0],
          'level4': null,
          'level5': null,
          'level6': null,
          'level7': null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      //API call to get next filter
      if(event?.user_preference) {
        let l_req = {
          level1: [event?.l1_name],
          // level2: [event?.l2_name],
          level3: req_val
        }
        if(Array.isArray(event?.l2_name)) {
          l_req['level2'] = event?.l2_name
        }
        else{
          l_req['level2'] = [event?.l2_name]
        }
        props.fetchFilterData(l_req,{key:'l4'});
      }
      else {
        let req = {
          level1: [department],
          level3: req_val
        }
        if(gender) {
          req['level2'] = [gender]
        }
        props.fetchFilterData(req,{key:'l4'});
      }
      resetRestFilters(2);
    };

    const onDCSChange = (val) => {
      setDCS(val);
      setRequest(old => {
        return {
          ...old,
          'level4': val,
          'level5': null,
          'level6': null,
          'level7': null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(3);
      let req = {
        level1: [department],
        level4: [val]
      }
      if(gender) {
        req['level2'] = [gender]
      }
      if(rbu) {
        req['level3'] = rbu
      }

      //API call to get next filter
      !shouldHideForCurrentClient("l5_name") && props.fetchFilterData(req,{key:'l9'});
    };

    const onLevel5Change = (val) => {
      setLevel5(val);
      setRequest(old => {
        return {
          ...old,
          'level5': val,
          'level6': null,
          'level7': null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(4);
      let req = {
        level1: [department],
        level5: [val]
      }
      if(gender) {
        req['level2'] = [gender]
      }
      if(rbu) {
        req['level3'] = rbu
      }
      if(dcs) {
        req['level4'] = [dcs]
      }

      //API call to get next filter
      props.fetchFilterData(req,{key:'l7'});
    };

    const onLevel6Change =(val)=>{
      setLevel6(val);
      setRequest(old => {
        return {
          ...old,
          'level6': val,
          'level7':null,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(5);
      let req = {
        level1: [department],
        level6: [val]
      }
      if(gender) {
        req['level2'] = [gender]
      }
      if(rbu) {
        req['level3'] = rbu
      }
      if(dcs) {
        req['level4'] = [dcs]
      }
      if(level5){
        req['level5'] = [level5]
      }

      props.fetchFilterData(req,{key:'l8'});
    }

    const onLevel7Change =(val)=>{
      setLevel7(val);
      setRequest(old => {
        return {
          ...old,
          'level7': val,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
      resetRestFilters(6);
      let req = {
        level1: [department],
        level7: [val]
      }
      if(gender) {
        req['level2'] = [gender]
      }
      if(rbu) {
        req['level3'] = rbu
      }
      if(dcs) {
        req['level4'] = [dcs]
      }
      if(level5){
        req['level5'] = [level5]
      }
      if(level6){
        req['level6'] = [level6]
      }
      props.fetchFilterData(req,{key:'l12'});

    }

    const onLevel8Change =(val)=>{
      setLevel8(val);
      setRequest(old => {
        return {
          ...old,
          'level8': val,
          'style_name': null,
          'color': null,
          'size': null,
          'storeGrade': null,
        }
      })
    }

    const onStyleChange = (val, event, that) => {
      // let valuesArray = handleSelectAll(val, event, that);
      let req_val = intersectionWith(val, styleOptions, isEqual);
      setStyle(req_val);
      setRequest(old => {
          return {
              ...old,
              'style_name': req_val,
          }
      });
  };


    const onColourChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, colourOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'color': valuesArray[0]
        }
      })
      setColour(valuesArray[1]);
      //API call to get next filter
    };
    const onSizeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'size': valuesArray[0]
        }
      })
      setSize(valuesArray[1]);
    };
    const onStoreGradeChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      // let value = intersectionWith(val, sizeOptions, isEqual);
      setRequest(old => {
        return {
          ...old,
          'storeGrade': valuesArray[0]
        }
      })
      setStoreGrade(valuesArray[1]);
    };

    const applyFilters = () => {
      if (getDefaultFiltersCondition({l1_name: department, l2_name: gender,l3_name:rbu})) 
      {
        setUserPreferenceFilter({"user_id":localStorage.getItem("user_id")},getLevelFiltersForUserPreference({l1_name:department,l2_name:gender,l3_name:rbu}))
        props.applyFilters({
          department,
          gender: request?.level2,
          rbu: request?.level3,
          dcs: request?.level4,
          level5: request?.level5,
          level6: request?.level6,
          level7: request?.level7,
          level8: request?.level8,
          style_name: request?.style_name,
          colour: request?.color,
          size: request?.size,
          store_grade: request?.storeGrade,
          country: request?.country,
          assortment_indicator: request?.assortment_indicator
        });
      } else {
        Notify.error("Please Select All Mandatory Options!!");
      }
    };

    const resetFilters = () => {
      setRequest(null)
      setDepartment(null);
      setGender(null);
      setRbu(null);
      setDCS(null);
      setStyle(null);
      setColour(null);
      setSize(null);
      setLevel5(null);
      setLevel6(null);
      setLevel7(null);
      setLevel8(null);
      setStoreGrade(null);
      setCountry(null);
      setAssortInd(null)
      props.resetTableData();
      props.resetFiltersOptions([]);
      props.setFilters(null)
    };

    const onCountryChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'country': valuesArray[0]
        }
      })
      setCountry(valuesArray[1]);    
    }

    const onAssortIndChange = (value, event, that) => {
      let valuesArray = handleSelectAll(value, event, that)
      setRequest(old => {
        return {
          ...old,
          'assortment_indicator': valuesArray[0]
        }
      })
      setAssortInd(valuesArray[1]);    
    }

    return (
      <>
        <section className="row section">
          {/* <Notification /> */}
          <div className="col-md-3 required">
            <label className="fnt-md fnt-bold fnt-bold">{props.filterLabels?.level1}</label>
            <Select
              name="department"
              value={department}
              onChange={onDepartmentChange}
              placeHolder={`Select ${props.filterLabels?.level1}`}
              options={departmentOptions?.length ? departmentOptions : []}
            />
          </div>
          <div className={`col-md-3 ${isMandatory("l2_name")}`}>
            <label className="fnt-md fnt-bold">{props.filterLabels?.level2}</label>
            <Select
              name="gender"
              value={gender}
              onChange={onGenderChange}
              placeHolder={`Select ${props.filterLabels?.level2}`}
              options={genderOptions?.length ? genderOptions : []}
            />
          </div>
          {
            !shouldHideForCurrentClient("l3_name") &&
            <>
            <div className={`col-md-3 ${isMandatory("l3_name")}`}>
              <label className="fnt-md fnt-bold">{props.filterLabels?.level3}</label>
              <MultiSelect
                name="rbu"
                dropdownValue={rbu}
                onDropDownChange={onRbuChange}
                placeHolder={`Select ${props.filterLabels?.level3}`}
                optionsArray={rbuOptions?.length ? rbuOptions : []}
              />
            </div>
            <div className="col-md-3 fill">
              <label className="fnt-md fnt-bold">{props.filterLabels?.level4}</label>
              <Select
                name="dcs"
                value={dcs}
                onChange={onDCSChange}
                placeHolder={`Select ${props.filterLabels?.level4}`}
                options={dcsOptions?.length ? dcsOptions : []}
              />
            </div>
            {
              !shouldHideForCurrentClient("l5_name") &&
              <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">{props.filterLabels?.level5}</label>
                <Select
                  name="level5"
                  value={level5}
                  onChange={onLevel5Change}
                  placeHolder={`Select ${props.filterLabels?.level5}`}
                  options={level5Options?.length ? level5Options : []}
                />
              </div>
            }
              {
              !shouldHideForCurrentClient("l6_name") &&
              <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">{props.filterLabels?.level6}</label>
                <Select
                  name="level6"
                  value={level6}
                  onChange={onLevel6Change}
                  placeHolder={`Select ${props.filterLabels?.level6}`}
                  options={level6Options?.length ? level6Options : []}
                />
              </div>
            }
             {
              !shouldHideForCurrentClient("l7_name") &&
              <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">{props.filterLabels?.level7}</label>
                <Select
                  name="level7"
                  value={level7}
                  onChange={onLevel7Change}
                  placeHolder={`Select ${props.filterLabels?.level7}`}
                  options={level7Options?.length ? level7Options : []}
                />
              </div>
            }
            {
              !shouldHideForCurrentClient("l8_name") &&
              <div className="col-md-3 fill">
                <label className="fnt-md fnt-bold">{props.filterLabels?.level8}</label>
                <Select
                  name="level8"
                  value={level8}
                  onChange={onLevel8Change}
                  placeHolder={`Select ${props.filterLabels?.level8}`}
                  options={level8Options?.length ? level8Options : []}
                />
              </div>
            }
          </>
          }
          {/* <div className="col-md-3">
            <label className="fnt-md fnt-bold">Style</label>
            <MultiSelect
              name="style_name"
              dropdownValue={style_name}
              onDropDownChange={onStyleChange}
              placeHolder={"Select Style"}
              optionsArray={styleOptions?.length ? styleOptions : []}
            />
          </div> */}
          <div className="col-md-3  fill">
              <label className="fnt-md fnt-bold">Style</label>
              <AsyncMulti
                  name="style"
                  dropdownValue={style_name}
                  onDropDownChange={onStyleChange}
                  placeHolder={'Select Style'}
                  optionsArray={styleOptions?.length ? styleOptions : []}
              />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Color</label>
            <MultiSelect
              name="colour"
              dropdownValue={colour}
              onDropDownChange={onColourChange}
              placeHolder={"Select Color"}
              optionsArray={colourOptions?.length ? colourOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Size</label>
            <MultiSelect
              name="size"
              dropdownValue={size}
              onDropDownChange={onSizeChange}
              placeHolder={"Select Size"}
              optionsArray={sizeOptions?.length ? sizeOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Store Grade</label>
            <MultiSelect
              name="storeGrade"
              dropdownValue={storeGrade}
              onDropDownChange={onStoreGradeChange}
              placeHolder={"Select Store Grade"}
              optionsArray={storeGradeOptions?.length ? storeGradeOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Country</label>
            <MultiSelect
              name="storeGrade"
              dropdownValue={country}
              onDropDownChange={onCountryChange}
              placeHolder={"Select Country"}
              optionsArray={countryOptions?.length ? countryOptions : []}
            />
          </div>
          <div className="col-md-3">
            <label className="fnt-md fnt-bold">Assortment Indicator</label>
            <MultiSelect
              name="storeGrade"
              dropdownValue={assortInd}
              onDropDownChange={onAssortIndChange}
              placeHolder={"Select Assort Indicator"}
              optionsArray={assortIndOptions?.length ? assortIndOptions : []}
            />
          </div>
          <div className="col-md-3">
            <button
              onClick={applyFilters}
              className="btn btn-primary filter-constraints-mode"
              title="Apply filters"
            >
              <i className="fa fa-filter mr-2" aria-hidden="true"></i>Filter
            </button>
            <button
              onClick={() => resetFilters("All")}
              className="btn undo-constraints-mode"
              title="Reset filters"
            >
              <i className="fa fa-undo mr-2"></i>Reset
            </button>
          </div>
          <div className="col-md-3 required fnt-md pt-5" style={{ color: "red" }}>
            <label></label>Fields are mandatory
        </div>
        </section>
      </>
    );
  }
);

const mapStateToProps = ({ constraints }) => ({
  filterLabels: constraints.filterlabels,
  departmentOptions: constraints.departmentOptions,
  genderOptions: constraints.genderOptions,
  rbuOptions: constraints.rbuOptions,
  dcsOptions: constraints.dcsOptions,
  level5Options: constraints.level5Options,
  level6Options: constraints.level6Options,
  level7Options: constraints.level7Options,
  level8Options: constraints.level8Options,
  styleOptions: constraints.styleOptions,
  colourOptions: constraints.colourOptions,
  sizeOptions: constraints.sizeOptions,
  storeGradeOptions : constraints.storeGradeOptions,
  countryOptions : constraints.countryOptions,
  assortIndOptions: constraints.assortIndOptions

});

const mapDispatchToProps = (dispatch) => ({
  resetFiltersOptions: (payload) => dispatch(resetFiltersOptions(payload)),
  fetchFilterData: (payload,filterKey,filterType) =>
    dispatch(fetchFilterData(payload,filterKey,filterType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConstraintsFilter);
