import React, { useCallback, useEffect, useState } from 'react';
import classnames from "classnames";
import debounce from "lodash.debounce"

import UserManagementCSS from "./UserManagement.module.scss";
import ReactTable from '../../components/Table/ReactTable';
import WithDynamicPlugins from '../../components/Table/WithPlugins'
import EditIconCell from '../../components/Table/Cellrenderer/EditIconCell';
import Notification from  "../../components/Notification/Notifications"
import * as Notify from "../../components/Notification/Notifications";

import UserManagementFilter from './UserManagementFilter';
import { connect } from 'react-redux';
import { CREATE_USER_MANAGEMENT_PAGE, EDIT_USER_MANAGEMENT_PAGE, USER_MANAGEMENT_PAGE } from '../../constants/routeConstants';
import { deleteUser, fetchUserData, resetAll } from './UserManagementActions';
import ColumnFilter from './ColumnFilter';
import ReactTollTip from '../../components/Table/Cellrenderer/ReactToolTip';
import { levelFiltersLabelFormatter } from '../../utils/filterLevelMapping';
import { isAshley } from '../../utils/commonUtilities';

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const UserManagement = (props) => {

    const [searchTerm, setSearchTerm] = useState("")
    const [status, setStatus] = useState(null)
    const [role, setRole] = useState(null)
    const [userData, setUserData] = useState([])

    const onStatusChange = (val) => {
        setStatus(val)
    }

    const onRoleChange = (val) => {
        setRole(val)
    }

    const prep = [
        {
            id: 1,
            isRequired: false,
            isMultiSelect: false,
            label: false,
            name: "status",
            isClearable: true,
            value:status,
            onChange:onStatusChange,
            placeholder: "Status",
            options:[{label:'Active',value:'active'},{label:'Inactive',value:'inActive'}]
        },
        {
            id: 2,
            isRequired: false,
            isMultiSelect: false,
            label: false,
            name: "role",
            isClearable: true,
            value:role,
            onChange:onRoleChange,
            placeholder: "Role",
            options:[{label:'Allocator',value:'Allocator'},{label:'Super User',value:'Super User'}]
        },

    ]
    

    const columns = React.useMemo(
        () => [
            {
                sticky: "left",
                Header: 'Full Name',
                accessor: 'full_name', 
                Cell: ({ value,row }) => <div className="d-flex align-items-center" style={{width: "100%"}}>
                <div className="circleIcon mx-4">
                  <div className={row.original.status==="active" ? "circle green" : "circle grey"}></div>
                </div>
                <div className="text-center">{value}</div>
                </div>,
                width: 250,
                // Filter: ColumnFilter
            },
            {
                Header: 'Email ID',
                accessor: 'email_id',
                width: 280,
                // Filter: ColumnFilter
            },
            // {
            //     Header: 'Retail Pro ID',
            //     accessor: 'retail_pro_id',
            //     width: 280,
            //     // Filter: ColumnFilter
            // },
            {
                Header: 'Role',
                accessor: 'role',
                width: 160,
                // Filter: ColumnFilter
            },
            // {
            //     Header: 'Password',
            //     accessor: 'password',
            //     width: 140,
            //     // disableFilters: true
            // },
            {
                Header: levelFiltersLabelFormatter("level1"),
                accessor: 'l1_name',
                Cell: (instance) => <ReactTollTip {...instance}/>,
                width: 300,
                // Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level2"),
                accessor: 'l2_name',
                Cell: (instance) => <ReactTollTip {...instance}/>,
                width: 300,
                // Filter: ColumnFilter
            },
            {
                Header: levelFiltersLabelFormatter("level3"),
                accessor: 'l3_name',
                Cell: (instance) => <ReactTollTip {...instance}/>,
                width: 300,
                // Filter: ColumnFilter
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                sticky: "right",
                Cell: (instance) => (
                    <div>
                        <EditIconCell
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            instance={instance}
                        ></EditIconCell>
                    </div>
                ),
                resizable: true,
                width: 100,
                // disableFilters: true
            }
        ],
        []
    )

    const handleEdit = (p_instance) => {
          props.history.push(`${USER_MANAGEMENT_PAGE}/edit/${p_instance?.uid}`);
      };
    
      const handleDelete = (p_instance) => {
        if (window.confirm("Are you sure you wish to delete this user?"))
          props.deleteUser({"email":p_instance?.email_id});
      };

    const onAddUserHandler = () => {
        props.history.push(CREATE_USER_MANAGEMENT_PAGE);
    }

    const debouncedHandleChange = useCallback(debounce((data) => {
        setSearchTerm(data)
    },700),[])

    const handleChange = (e) => {
        debouncedHandleChange(e.target.value)
    }

    useEffect(() => {
        props.fetchUserData({})
        return () => {
            props.resetAll()
          }
    },[])



    useEffect(() => {
        if (props.deleteSuccess) {
          Notify.success("User deleted Successfully!!");
          props.fetchUserData({})
        }
        if (props.deleteError) {
          Notify.error("Error in deleting user!!");
        }
    }, [props.deleteError, props.deleteSuccess]);


    useEffect(() => {
        let l_userData = props.userData
        if (role || status || searchTerm) {
            let l_searchString = searchTerm.trim().toLowerCase();
            l_userData =
              l_userData.length &&
              l_userData.filter(
                (e) =>
                  (e.full_name.toLowerCase().match(l_searchString) ||
                  e.email_id.toLocaleLowerCase().match(l_searchString)) &&
                  e.role.match(role ? role.value : "") &&
                  e.status.match(status ? status.value : "")
              );
        }
        setUserData(l_userData)
    }, [searchTerm, role, status])

    useEffect(() => {
        props.userData?.length && setUserData(props.userData)
    },[props.userData])


    return (
        <div className={UserManagementCSS.user_management__container}>
        {/* <Notification /> */}
            <div className="container__header">
                <h1 className="fnt-lg fnt-bold">User Management</h1>
                {/* <div className="container__button">
                    <button className={classnames("btn", "btn-primary", UserManagementCSS.user_management__config_button)}>
                        <i
                            className="fa fa-cog mr-2"
                            title="Configure"
                            aria-hidden="true"
                        ></i>
                        Configure
                    </button>
                </div> */}
            </div>
            <section className={classnames("", UserManagementCSS.user_management__container_body)}>
                <div className={classnames("row", UserManagementCSS.user_management__align_baseline)}>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-3 col-sm-4 my-sm-1">
                                <button className="btn btn-primary w-100"
                                    onClick={onAddUserHandler}
                                >
                                    <i
                                        className="fa fa-user-o mr-2"
                                        title="Configure"
                                        aria-hidden="true"
                                    ></i>
                                    Add User
                                </button>
                            </div>
                            {/* <div className="col-md-3 col-sm-4 my-sm-1">
                                <button className="btn btn-outline-secondary w-100" >
                                    <i
                                        className="fa fa-file-excel-o mr-2"
                                        title="Configure"
                                        aria-hidden="true"
                                    ></i>
                                    Upload
                                </button>
                            </div> */}
                            {/* <div className="col-md-3 col-sm-4 my-sm-1">
                                <button className="btn btn-primary w-100">
                                    <i
                                        className="fa fa-download mr-2"
                                        title="Configure"
                                        aria-hidden="true"
                                    ></i>
                                    Download
                                </button>


                            </div> */}
                        </div>
                    </div>


                    <UserManagementFilter data={prep} />
                    <div className="col-md-2  my-sm-1">
                        <div className="input-group">
                            <input  type="search" className={classnames("form-control")} placeholder="Search by Name/Email" name="search" onChange={(e) => handleChange(e)}/>
                        </div>
                    </div>

                    <div className={classnames("col-md-12 mt-4",UserManagementCSS.user__management__table)}>
                    {(props.error) ?  (<div className="error">{props.error}</div>) :
                       <ReactTableWithPlugins
                            // shouldPagination
                            embededScroll
                            totalRecordsLen={userData.length}
                            data={userData}
                            columns={columns}
                            style= {{minWidth:'50rem', maxWidth:'fit-content'}}
                            renderMarkup='TableMarkup'
                            initialHiddenColumns={isAshley() ? ['retail_pro_id'] : []}
                            // keyRT="sortAndSearch"
                            tableId="user_management"
                        />}
                    </div>
                </div>
            </section>
        </div>
    )
}


const mapStateToProps = ({ userManagement }) => ({
    userData: userManagement.userData,
    error : userManagement.error,
    deleteSuccess: userManagement.deleteSuccess,
    deleteError: userManagement.deleteError,
})

const mapDispatchToProps =  (dispatch) => ({
    fetchUserData: (payload) => dispatch(fetchUserData(payload)),
    deleteUser: (payload) => dispatch(deleteUser(payload)),
    resetAll: () => dispatch(resetAll()),
})


export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
