import { isEmpty } from "lodash";
import { takeLatest, put, all, call } from "redux-saga/effects";
import * as Api from "../../routes/api"
import {  STORE_GROUP_ACTION,
          storeGroupActionSuccess, 
          storeGroupActionError,  
          STORE_DETAILS_ACTION, 
          storeDetailsActionSuccess, 
          storeDetailsActionError, 
          CREATE_STOREGROUP_FILTERS_ACTION,
          createStoreGroupFiltersActionSuccess,
          createStoreGroupFiltersActionError,
          CREATE_STOREGROUP_STOREDATA_ACTION,
          createStoreGroupStoreDataActionSuccess,
          createStoreGroupStoreDataActionError,
          CREATE_STOREGROUP_ACTION,
          createStoreGroupActionSuccess,
          createStoreGroupActionError,
          getStoreSecondDataSuccess,
          EDIT_STOREGROUP_ACTION,
          editStoreGroupActionSuccess,
          editStoreGroupActionError,
          DELETE_STOREGROUP,
          deleteStoreGroupSuccess,
          deleteStoreGroupError,
          STORE_GROUP_ACTION_FOR_EDIT,
          storeGroupActionForEditSuccess,
          storeGroupActionForEditError,
         } from "./StoreGroupAction"
import { cloneDeep } from "lodash";

// Worker Saga
function* storeGroupWorkerSaga(action) {
    try {
      const response = yield call(Api.getStoreGroupApi,action.payload)
      if (response.status) {
        yield put(storeGroupActionSuccess({'data':response.data.data}))
      }
      else{
        yield put(storeGroupActionError({'error':response.message}));
      }

    } catch (error) {
        yield put(storeGroupActionError({error :"Something Went Wrong!!"}));
    }
}

function* storeGroupForEditWorkerSaga(action) {
  try {
    const response = yield call(Api.getStoreGroupApi,action.payload)
    if (response.status) {
      yield put(storeGroupActionForEditSuccess({'data':response.data.data}))
    }
    else{
      yield put(storeGroupActionForEditError({'error':response.message}));
    }

  } catch (error) {
      yield put(storeGroupActionForEditError({error :"Something Went Wrong!!"}));
  }
}



function* storeDetailsWorkerSaga(action) {
  try {
    const response = yield call(Api.getStoresStoreGroupApi,action.payload)
    if (response.status) {
      yield put(storeDetailsActionSuccess({'data':response.data.data}))
    }
    else{
      yield put(storeDetailsActionError({'error':response.message}));
    }

  } catch (error) {
      yield put(storeDetailsActionError({error :"Something Went Wrong!!"}));
  }
}

function* createStoreGroupFiltersWorkerSaga(action) {
  try {
    let {payload, isEdit, levelOptions} = action
    let req = {}
    for (let i in payload) {
      if(!isEmpty(payload[i])) {
        req[i] = payload[i]?.map(val => val.value)
      }
    }
    const [levelFilter, otherFilters] = yield all([
      call(Api.getStoreLevelFilterApi,req),
      call(Api.getStoreFilterApi,req)
    ])
    const response = {data : [{...levelFilter?.data?.data[0], ...otherFilters?.data?.data[0]}], status:(levelFilter?.status && otherFilters?.status)}
    if(levelOptions?.persistFilterOption) {
      let { level1Options, level2Options} = levelOptions
      if(level1Options?.length) 
        response.data[0].l1_name = level1Options
      if(level2Options?.length) 
        response.data[0].l2_name = level2Options
    }
    if(req?.brand) {
      delete response.data[0]?.brand
    }
    if(req?.country) {
      delete response.data[0]?.country
    }
    if(req?.channel) {
      delete response.data[0]?.channel
    }
    if (response.status) {
      if(payload?.isCountry) {
        delete response?.data[0]?.l1_name
        delete response?.data[0]?.l2_name
        delete response?.data[0]?.l3_name
      }
      yield put(createStoreGroupFiltersActionSuccess({'data':response.data}))
    }
    else{
      yield put(createStoreGroupFiltersActionError({'error':response.message}));
    }

  } catch (error) {
      yield put(createStoreGroupFiltersActionError({error :"Something Went Wrong!!"}));
  }
}

function* createStoreGroupStoreDataWorkerSaga(action) {
  console.log(action)
  try {
    const response = yield call(Api.getStoreApi,action.payload)
    console.log(action.payload)
    if (response.status) {
      let responseWithDummyId = response?.data?.data?.map((val) => {
        return {
          ...val,
          dummyId: 2
        }
      })
      yield put(createStoreGroupStoreDataActionSuccess({'data':responseWithDummyId}))
      // if(action.editingOrCreatingStoreGroup === "firstTable"){
      // } else if (action.editingOrCreatingStoreGroup === "secondTable") {
      //   yield put(getStoreSecondDataSuccess({'data':response.data}))
      // }
     
    }
    else{
      yield put(createStoreGroupStoreDataActionError({'error':response.message}));
    }

  } catch (error) {
      yield put(createStoreGroupStoreDataActionError({error :"Something Went Wrong!!"}));
  }
}

function* createStoreGroupStoreWorkerSaga(action) {
  try {
    const response = yield call(Api.createStoreGroupApi,action.payload)
    if (response.data.status) {
      yield put(createStoreGroupActionSuccess({'data':response.data}))
    }
    else if(response?.data?.status === false) {
      yield put(createStoreGroupActionError({'error': "Error"}));
    }
    else{
      yield put(createStoreGroupActionError({'error':response.message}));
    }

  } catch (error) {
      yield put(createStoreGroupActionError({error: "Something Went Wrong!!"}));
  }
}

function* editStoreGroupStoreWorkerSaga(action) {
  try {
    const response = yield call(Api.editStoreGroupApi,action.payload)
    if (response.status) {
      yield put(editStoreGroupActionSuccess({'data':response.data}))
    }
    else{
      yield put(editStoreGroupActionError({'error':response.message}));
    }

  } catch (error) {
      yield put(editStoreGroupActionError({error :"Something Went Wrong!!"}));
  }
}

function* deleteStoreGroupSagaWorkerSaga(action) {
  const {payload} = action
  console.log(payload)
  try {
    const response = yield call(Api.deleteStoreGroupApi,action.payload)
    if (response.status) {
      yield put(deleteStoreGroupSuccess({'data':response.data}))
    }
    else{
      yield put(deleteStoreGroupError({'error':response.message}));
    }

  } catch (error) {
      yield put(deleteStoreGroupError({error :"Something Went Wrong!!"}));
  }
}

// Watcher Saga
function* storeGroupWatcherSaga() {
  yield takeLatest(STORE_GROUP_ACTION, storeGroupWorkerSaga);
}

function* storeGroupForEditWatcherSaga() {
  yield takeLatest(STORE_GROUP_ACTION_FOR_EDIT, storeGroupForEditWorkerSaga);
}

function* storeDetailsWatcherSaga() {
  yield takeLatest(STORE_DETAILS_ACTION, storeDetailsWorkerSaga);
}

function* createStoreGroupFiltersWatcherSaga() {
  yield takeLatest(CREATE_STOREGROUP_FILTERS_ACTION, createStoreGroupFiltersWorkerSaga);
}

function* createStoreGroupStoreDataWatcherSaga() {
  yield takeLatest(CREATE_STOREGROUP_STOREDATA_ACTION, createStoreGroupStoreDataWorkerSaga);
}

function* createStoreGroupSagaWatcherSaga() {
  yield takeLatest(CREATE_STOREGROUP_ACTION, createStoreGroupStoreWorkerSaga);
}

function* editStoreGroupSagaWatcherSaga() {
  yield takeLatest(EDIT_STOREGROUP_ACTION, editStoreGroupStoreWorkerSaga);
}

function* deleteStoreGroupSagaWatcherSaga() {
  yield takeLatest(DELETE_STOREGROUP, deleteStoreGroupSagaWorkerSaga);
}

export function* storeGroupSaga() {
  yield all([storeGroupWatcherSaga(), 
             storeGroupForEditWatcherSaga(),
             storeDetailsWatcherSaga(), 
             createStoreGroupFiltersWatcherSaga(),
             createStoreGroupStoreDataWatcherSaga(),
             createStoreGroupSagaWatcherSaga(),
             editStoreGroupSagaWatcherSaga(),
             deleteStoreGroupSagaWatcherSaga(),
            ]);
}
