export const HEADER = "New Stores"
export const RESERVED_HEADER = "Reserved Inventory"
export const TABLE_HEADERS = {
    STORES: "Store #",
    START_DATE: "Reservation Start Date",
    OPENING_DATE: "Store Opening Date",
    LEAD_TIME: "Lead Time(Days)",
    RESERVED_QTY: "Reserved Qty",
    STYLE_COLOR: "Style Color #",
    DESC: "Store Name",
    STORE_IDS: "Store ID",
    STORE_NAME: "Store Name",
    ALLOCATED_QTY: "Allocated Qty",
    APPROVED_QTY: "Approved Qty",
    STYLE_DESC: "Style Description"
}